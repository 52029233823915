import { useRef, useState, useLayoutEffect } from 'react';
import FAIcon from 'components/ui/FAIcon';
import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import Menu from 'components/menu/Menu';
import { useField } from 'frmx';
import MenuItem from 'components/menu/MenuItem';
import useKeys from '@flowsn4ke/usekeys';
import { IconButton } from '@material-ui/core';

export default function DragScrollContainer({ children, className, sortingOrders, scrollClassName }) {
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const scrollingRef = useRef(false);

  useLayoutEffect(() => {
    const scrollContainerNode = containerRef.current?.container?.current;
    if (!scrollContainerNode) return;

    const updateScrollState = () => {
      setIsBeginning(scrollContainerNode.scrollLeft <= 4);
      setIsEnd(
        Math.floor(scrollContainerNode.scrollLeft + scrollContainerNode.clientWidth) >=
          scrollContainerNode.scrollWidth - 4
      );
    };

    // Initial state check
    updateScrollState();

    // Event listener for resizing (in case container size changes)
    const resizeObserver = new ResizeObserver(updateScrollState);
    resizeObserver.observe(scrollContainerNode);

    return () => resizeObserver.disconnect();
  }, [children]);

  const handleScroll = () => {
    const scrollContainerNode = containerRef.current?.container?.current;
    if (!scrollContainerNode) return;

    setIsBeginning(scrollContainerNode.scrollLeft <= 4);
    setIsEnd(
      Math.floor(scrollContainerNode.scrollLeft + scrollContainerNode.clientWidth) >=
        scrollContainerNode.scrollWidth - 4
    );
  };

  const handleScrollContinuous = (scrollAmount) => {
    const scrollContainerNode = containerRef.current?.container?.current;
    if (!scrollContainerNode || scrollingRef.current) return;

    scrollingRef.current = true;
    scrollIntervalRef.current = setInterval(() => {
      scrollContainerNode.scrollBy({ left: scrollAmount, behavior: 'auto' });
    }, 10);
  };

  const stopContinuousScroll = () => {
    scrollingRef.current = false;
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  return (
    <div className={`flex items-center gap-2${className}`}>
      {sortingOrders.length > 0 && (
        <div>
          <SortingMenu sortingOrders={sortingOrders} />
        </div>
      )}
      <div className="flex items-center relative flex-nowrap w-full max-w-full overflow-hidden">
        {!isBeginning && (
          <button
            className="z-10 w-8 h-full cursor-pointer absolute top-0 left-0 rounded-r-2xl backdrop-blur-sm hover:backdrop-blur transition"
            onMouseDown={() => handleScrollContinuous(-250)}
            onMouseUp={stopContinuousScroll}
            onMouseLeave={stopContinuousScroll}
            onTouchStart={() => handleScrollContinuous(-250)} // For mobile
            onTouchEnd={stopContinuousScroll} // For mobile
          >
            <FAIcon
              collection="far"
              icon="angles-left"
              size="small"
            />
          </button>
        )}
        <ScrollContainer
          className={classNames(
            'scroll-container scroll-smooth flex space-x-2 p-1 cursor-grab active:cursor-grabbing scrollbar-hide',
            scrollClassName
          )}
          ref={containerRef}
          onScroll={handleScroll}
          onEndScroll={handleScroll}
          hideScrollbars={false}
        >
          {children}
        </ScrollContainer>
        {!isEnd && (
          <button
            className="z-10 w-8 h-full cursor-pointer absolute top-0 right-0 rounded-l-2xl backdrop-blur-sm hover:backdrop-blur transition"
            onMouseDown={() => handleScrollContinuous(250)}
            onMouseUp={stopContinuousScroll}
            onMouseLeave={stopContinuousScroll}
            onTouchStart={() => handleScrollContinuous(250)} // For mobile
            onTouchEnd={stopContinuousScroll} // For mobile
          >
            <FAIcon
              collection="far"
              icon="angles-right"
              size="small"
            />
          </button>
        )}
      </div>
    </div>
  );
}

function SortingMenu({ sortingOrders }) {
  const k = useKeys();
  const { value: order, setValue: setOrder } = useField('order');
  const { value: sort, setValue: setSort } = useField('sort');

  return (
    <Menu
      button={() => (
        <IconButton size="small">
          <FAIcon
            collection="fas"
            icon="sort"
            size="medium"
          />
        </IconButton>
      )}
      items={sortingOrders.map((sortingOrder, sortingOrderIdx) => (
        <MenuItem
          key={k(sortingOrderIdx)}
          icon={sortingOrder.icon}
          label={sortingOrder.label}
          onClick={() => {
            setOrder(sortingOrder.sortKey);
            setSort(sortingOrder.labelKey);
          }}
          className={order === sortingOrder.sortKey && sort === sortingOrder.labelKey ? 'bg-gray-200' : ''}
        />
      ))}
    />
  );
}
