const TicketExportsNew = (configuration) =>
  [
    // Informations générales sur le ticket
    { label: 'ticketNumber', key: 'number', show: true },
    { label: 'createdDate', key: 'created_at', show: true },
    { label: 'priority', key: 'priorities', show: true }, // ajouter le label dans le ticket !!
    { label: 'status', key: 'status', show: true },
    { label: 'title', key: 'title', show: true },
    { label: 'description', key: 'description', show: true },
    { label: 'typology', key: 'types', show: true },
    // Localisation et gestion des lieux
    { label: 'locationName', key: 'location_name', show: true },
    { label: 'exportLocation_reference', key: 'location_reference', show: true },
    { label: 'address', key: 'address', show: true },
    { label: 'zone', key: 'zone', show: true },
    // { label: 'zipCode', key: 'postalCode', show: true },
    // { label: 'city', key: 'city', show: true },
    { label: 'managersTitle', key: 'managers', show: true },

    // // Client / Utilisateur
    // {
    //   label: configuration.isContractor ? 'client' : 'creationClient',
    //   key: configuration.isContractor ? '_owner' : '_user',
    //   show: true
    // },
    // { label: 'creator', key: 'creator', show: configuration.isBlachere ? true : false },
    { label: 'contacts', key: 'contacts', show: true },

    // Travaux et équipements
    { label: 'skills', key: 'skills', show: true },
    { label: 'equipmentName', key: 'equipment_name', show: true },

    // // Interventions et planifications
    { label: 'contractors', key: 'contractors', show: true },
    { label: 'programmedVisitDate', key: 'planned_dates', show: true },
    { label: 'interventionDate', key: 'intervention_dates', show: true },
    // { label: 'interventionDuration', key: 'intervention_total_duration', show: true },

    // {
    //   label: 'indicationScheduledVisitDate',
    //   key: 'indication_scheduled_visit_date',
    //   show: configuration.isClient ? true : false
    // },
    // { label: 'replanReason', key: 'replan_reason', show: true },
    // {
    //   label: 'visitDatesContractor',
    //   key: 'visit_dates_contractor',
    //   show: configuration.isContractor ? true : false
    // },
    // {
    //   label: 'interventionDatesContractor',
    //   key: 'intervention_dates_contractor',
    //   show: configuration.isContractor ? true : false
    // },

    // // Coûts et facturation
    { label: 'htInterventionAmount', key: 'invoice_amount', show: true },
    // {
    //   label: 'totalCostsConsumptionsStock',
    //   key: 'total_cost_consumptions',
    //   show: configuration?.feature?.inventory ? true : false
    // },
    // { label: 'facturationPrice', key: 'facturation_price', show: true },
    { label: 'billingReference', key: 'invoice_reference', show: true },
    // {
    //   label: 'billingPriceContractor',
    //   key: 'facturation_price_contractor',
    //   show: configuration.isContractor ? true : false
    // },
    // {
    //   label: 'billingReferenceContractor',
    //   key: 'reference_facture_contractor',
    //   show: configuration.isContractor ? true : false
    // },
    // { label: 'vatRate2', key: '_tva', show: true },

    // // Devis
    {
      label: 'quotesPrice',
      key: 'quote_amount',
      show: true
    },
    {
      label: 'quotesReference',
      key: 'quote_reference',
      show: true
    },
    // { label: 'quotesPricewaitingValidation', key: 'devis_price_waiting_validation', show: true },
    // { label: 'dateValidationQuotes', key: 'validation_date_devis', show: true },
    // {
    //   label: 'quotesPriceContractor',
    //   key: 'devis_price_contractor',
    //   show: configuration.isContractor ? true : false
    // },
    // {
    //   label: 'quotesReferenceContractor',
    //   key: 'reference_devis_contractor',
    //   show: configuration.isContractor ? true : false
    // },

    // // Historique et suivi
    // { label: 'history', key: 'history', show: true },
    { label: 'ticketTermination', key: 'finished_at', show: true },

    // // Comptabilité et domaines (optionnel)
    // {
    //   label: 'menuItemComptability',
    //   key: 'state_facture',
    //   show: configuration.isContractor ? false : true
    // },
    { label: 'domains', key: 'domains', show: configuration.job_domains?.length ? true : false },
    // { label: 'subDomains', key: 'sub_domains', show: configuration.isBlachere ? true : false }
  ].filter((e) => e.show);

export default TicketExportsNew;
