import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { truncateText } from 'utils/uiUtils';
import useStyles from 'layouts/entities/Preview.styles';
import { useAuth } from 'hooks/useAuth';
import classNames from 'classnames';
import compute_time_ago from 'utils/dates';

export default function TicketPreview2({ number, title, created_at, description, className }) {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();

  console.log({ created_at });

  return (
    <div className={classNames('w-full p-3', className)}>
      <div className="flex justify-between">
        <div className="flex items-center justify-between w-full mr-2">
          <div className="font-semibold text-xs flex items-center text-[#363640]">#{number}</div>
          <div>{compute_time_ago(created_at)}</div>
        </div>
      </div>

      <div className="mt-2 md:flex">
        <div
          className={classes.title}
          style={{ color: auth.interface.isMaster ? theme.palette.primary.main : '' }}
        >
          {title}
        </div>
      </div>

      <div className={clsx(classes.l, classes.description)}>
        <div>{truncateText(description, 360)}</div>
      </div>
    </div>
  );
}
