import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Portal,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  ListItemSecondaryAction,
  Popover
} from '@material-ui/core';
import {
  Fullscreen,
  FullscreenExit,
  InfoRounded,
  Refresh,
  EditOutlined,
  MoreVert as MoreVertIcon,
  ArrowRight as ArrowRightIcon,
  Save as SaveIcon
} from '@material-ui/icons';

import Pagination from '@material-ui/lab/Pagination';

import { pick } from 'lodash-es';

import useStyles from './Timeline.styles';

import { useState, useEffect, useMemo, useRef } from 'react';

import { addListener, removeListener } from 'libs/react-calendar-timeline/resize-detector/container';
import useNotifications from 'hooks/useNotifications';

import ReactTimeline, {
  TimelineMarkers,
  TodayMarker,
  TimelineHeaders,
  DateHeader,
  SidebarHeader
} from 'libs/react-calendar-timeline';
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'libs/react-calendar-timeline/lib/Timeline.css';
import EquipmentPreview from 'entities/Equipment/EquipmentPreview';
import Preview from 'layouts/entities/Preview';
import LocationPreview from 'entities/Location/LocationPreview';
import { useEntity } from 'contexts/entities/entityContext';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import BlurredProgress from 'components/BlurredProgress';

import Ticket from 'entities/Ticket/Ticket';
import periodicities from 'constants/periodicities';
import { Empty } from 'layouts/entities/List';
import Maintenance from 'entities/Maintenance/Maintenance';
import FAIcon from 'components/ui/FAIcon';
import { useRole } from 'hooks/useRole';
import { useSelector } from 'react-redux';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { dateToLocalFormat } from 'utils/dates';
import { useAuth } from 'hooks/useAuth';
import { add, getTime, isAfter, isBefore, isEqual } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { getElement } from 'store/maintenancesSlice';
import Avatar from 'components/Avatar';

import { MAINTENANCE_PAGE_SIZE } from 'constants/maintenances';

import useKeys from '@flowsn4ke/usekeys';
import { updateElement, updateElement as updateElementPeriod } from 'store/periodsSlice';
import Intervener from 'entities/Intervener/Intervener';
import { Form, Submit } from 'frmx';
import { useConfiguration } from 'hooks/useConfiguration';
import axios from 'axios';
import { KINGFISHER_DEMO_ACCOUNT_ID, apiBaseURL } from 'index';
import { saveAs } from 'file-saver';
import CalendarPopover from 'components/ui/CalendarPopover';
import EntityX from 'components/inputs/EntityX';
import { updateElement as updateMaintenanceLocationsOrEquipments } from 'store/maintenancesSlice';
import Dialog from 'components/Dialog';
import { TimelineElement } from './TimelineElement';
import useOutsideClick from 'hooks/useOutsideClick';

const periodsEdge = {
  day: 1,
  week: 1,
  '2week': 2,
  '3week': 3,
  month: 4,
  '2month': 4,
  trim: 7,
  qrim: 7,
  semestre: 12,
  year: 30,
  reduit: 30,
  complet: 12,
  '2year': 30,
  '3year': 30,
  '5year': 30
};

export default function Timeline({
  elements,
  maintenanceId,
  headerRef,
  setFullscreen,
  fullscreen,
  fetchElements,
  isLoading,
  sort,
  ticketView,
  displayAllMaintenances
}) {
  const { db, icon, translations } = useEntity();
  const dbMaintenances = useSelector((state) => state['maintenances']).db;
  const maintenance = dbMaintenances[maintenanceId];
  const role = useRole();

  const [selectedItem, setSelectedItem] = useState([]);
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [ticketId, setTicketId] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleTimeStart, setVisibleTimeStart] = useState(null);
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(null);
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false);
  const elementsLength = elements?.count || 0;
  const { dispatch } = useAsyncDispatch();
  const fetch = () => maintenanceId && dispatch(getElement, { fetchAllData: true }, {}, { id: maintenanceId });

  useEffect(() => [setPage(1), fetch()], [maintenanceId]);
  useEffect(() => [setVisibleTimeEnd(null), setVisibleTimeStart(null)], [page, maintenanceId]);

  useEffect(() => {
    if (!!elements) {
      setGroups(
        elements.map((maintenance) => ({
          id: maintenance._id,
          maintenance: db[maintenance._id]
        }))
      );

      setItems(
        elements
          .map((element) => db[element._id])
          .reduce(
            (a, maintenance) =>
              a.concat(
                maintenance.periods.map((period, index) => {
                  const isAlert =
                    period.current &&
                    maintenance.alert?.enable &&
                    (isEqual(new Date(), new Date(period.alert_date)) ||
                      isAfter(new Date(), new Date(period.alert_date))) &&
                    (isEqual(new Date(), new Date(period.end_time)) || isBefore(new Date(), new Date(period.end_time)));

                  const isLate = period.current && isAfter(new Date(), new Date(period.end_time));

                  return {
                    id: period._id,
                    group: maintenance._id,
                    period: {
                      index: index + 1,
                      ...period,
                      isAlert,
                      isLate
                    },
                    start_time: getTime(new Date(period.start_time)),
                    end_time: getTime(
                      period.current && maintenance._preventif.compute === 'regulatory' && isLate
                        ? new Date()
                        : new Date(period.end_time)
                    )
                  };
                })
              ),
            []
          )
      );
    }
  }, [elements]);

  useEffect(() => {
    if (!!groups?.length) {
      const group_start = groups[sort.sort === 1 ? 0 : groups?.length - 1];
      const item_start = items.filter((item) => item.group === group_start.id).slice(-1)[0];

      const group_end = groups[sort.sort === -1 ? 0 : groups?.length - 1];
      const item_end = items.filter((item) => item.group === group_end.id).slice(-1)[0];

      const unixTimeStart = getTime(new Date(item_start?.start_time));
      const unixTimeEnd = getTime(new Date(item_end?.end_time));

      const configStart = -Math.abs(periodsEdge[group_start.maintenance.config.type]);
      const configEnd = Math.abs(periodsEdge[group_end.maintenance.config.type]);

      const start = add(unixTimeStart, { days: configStart });
      const end = add(unixTimeEnd, { days: configEnd });

      setVisibleTimeStart(start);
      setVisibleTimeEnd(end);
    }
  }, [items]);

  const fetchPage = (page) => {
    setPage(page);
    fetchElements(null, true, page * MAINTENANCE_PAGE_SIZE - MAINTENANCE_PAGE_SIZE, MAINTENANCE_PAGE_SIZE);
  };

  const count = useMemo(() => (!!elements ? Math.ceil(elements.count / MAINTENANCE_PAGE_SIZE) : 0), [elements?.count]);

  const handleItemMove = (itemId, dragTime) => {
    items.forEach((item) => {
      if (item.id === itemId) {
        const start_time = dragTime;
        const end_time = dragTime + (item.end_time - item.start_time);

        setConfirmUpdateOpen({ start_time, end_time, itemId });
      }
    });
  };

  const handleItemResize = (itemId, time, edge) => {
    items.forEach((item) => {
      if (item.id === itemId) {
        const start_time = edge === 'left' ? time : item.start_time;
        const end_time = edge === 'left' ? item.end_time : time;

        setConfirmUpdateOpen({ start_time, end_time, itemId });
      }
    });
  };

  const updateItem = () => {
    const { start_time, end_time, itemId } = confirmUpdateOpen;

    setItems(
      items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            start_time,
            end_time
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <TimelineComponent
      displayAllMaintenances={displayAllMaintenances}
      fullscreen={fullscreen}
      headerRef={headerRef}
      maintenanceId={maintenanceId}
      maintenance={maintenance}
      count={count}
      ticketId={ticketId}
      confirmUpdateOpen={confirmUpdateOpen}
      updateItem={updateItem}
      setSelectedItem={setSelectedItem}
      setConfirmUpdateOpen={setConfirmUpdateOpen}
      isLoading={isLoading}
      elements={elements}
      icon={icon}
      translations={translations}
      groups={groups}
      items={items}
      selectedItem={selectedItem}
      visibleTimeStart={visibleTimeStart}
      visibleTimeEnd={visibleTimeEnd}
      handleItemMove={handleItemMove}
      handleItemResize={handleItemResize}
      ticketView={ticketView}
      page={page}
      fetchPage={fetchPage}
      setTicketId={setTicketId}
      role={role}
      setFullscreen={setFullscreen}
      elementsLength={elementsLength}
    />
  );
}

function TimelineComponent({
  fullscreen,
  page,
  setTicketId,
  role,
  setFullscreen,
  fetchPage,
  headerRef,
  maintenanceId,
  maintenance,
  count,
  ticketId,
  confirmUpdateOpen,
  updateItem,
  setSelectedItem,
  setConfirmUpdateOpen,
  isLoading,
  elements,
  icon,
  translations,
  groups,
  items,
  selectedItem,
  displayAllMaintenances,
  visibleTimeStart,
  visibleTimeEnd,
  handleItemMove,
  handleItemResize,
  ticketView,
  elementsLength
}) {
  const auth = useAuth();
  const classes = useStyles();
  const [isDownloading, setIsDownloading] = useState(false);
  const [openChangePeriods, setOpenChangePeriods] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const { t } = useTranslation();
  const notify = useNotifications();
  const { permission } = useRole();

  const canUpdateMaintenance = permission('maintenances', 'update');

  const companyPermission =
    !!maintenance && auth.interface._company._id === (maintenance?._client || maintenance?._contractor)?._id;

  const handleExportMaintenanceLate = () => {
    setIsDownloading(true);
    axios
      .post(`${apiBaseURL}/miscs/export/maintenance/${maintenanceId}`)
      .then((res) => {
        setIsDownloading(false);

        const filename = res.request
          .getResponseHeader('Content-Disposition')
          .split(';')[1]
          .replace(/filename=/, '')
          .replace(/"/g, '')
          .trim();

        const csvExport = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        });
        saveAs(csvExport, filename);
        notify.success(t('file-downloaded'));
      })
      .catch((err) => {
        setIsDownloading(false);
        notify.error();
      });
  };

  const [period, set_period] = useState(null);

  const period_ids = useMemo(() => {
    if (!period) return [];

    const period_group = groups.find((group) => String(group.id) === String(period.group));

    return period_group.maintenance.periods.map((period, period_idx) => ({
      ticket_id: period._ticket._id,
      index: period_idx
    }));
  }, [period]);

  const handle_click_left_arrow = () => {
    const index = period_ids.findIndex((period) => period.ticket_id === ticketId);

    // Set the ticket id to the previous ticket id
    const ticket_id = period_ids[index - 1]?.ticket_id;
    setTicketId(ticket_id);

    return ticket_id;
  };

  const ref = useRef(null);
  useOutsideClick(ref, () => setOpenMenu(false));

  const handle_click_right_arrow = () => {
    const index = period_ids.findIndex((period) => period.ticket_id === ticketId);

    // Set the ticket id to the next ticket id
    const ticket_id = period_ids[index + 1]?.ticket_id;
    setTicketId(ticket_id);

    return ticket_id;
  };

  const handle_relance_presta = () => {
    setTimeout(() => {
      notify.success(t('relance-all-presta'));
    }, 700);
  };

  return (
    <Box className="flex flex-col relative h-full min-w-[56vw]">
      <Portal container={headerRef?.current}>
        <header className="bg-gray-100">
          {!displayAllMaintenances && (
            <Box className="flex items-center justify-between w-full">
              <Maintenance childrenId={maintenanceId}>
                <Button>
                  {auth.interface.isContractor && (
                    <Avatar
                      entity={{
                        name: maintenance?._clients[0]?.name,
                        avatar: maintenance?._clients[0]?.avatar
                      }}
                      round={false}
                      isUser={false}
                      shadow={false}
                      size={'small2'}
                      style={{
                        position: 'relative',
                        right: 10
                      }}
                    />
                  )}
                  {!auth.interface.isContractor && (
                    <FAIcon
                      className="flex items-center"
                      collection="fal"
                      icon="file-alt"
                      size="large"
                    />
                  )}

                  <Box className="flex items-start ml-2 flex-col relative">
                    <strong className="text-base text-left">{maintenance?.name}</strong>
                    <strong className="text-xs">{t(periodicities[maintenance?.defaults?.config.type])}</strong>
                  </Box>
                </Button>
              </Maintenance>

              <div className="flex items-center">
                {companyPermission && canUpdateMaintenance && (
                  <>
                    <Maintenance
                      openFormEditOnClick
                      childrenId={maintenanceId}
                    >
                      <IconButton>
                        <EditOutlined />
                      </IconButton>
                    </Maintenance>
                    <Button onClick={() => setOpenChangePeriods(true)}>{t('changePeriods')}</Button>
                  </>
                )}

                <ChangePeriods
                  openChangePeriods={openChangePeriods}
                  setOpenChangePeriods={setOpenChangePeriods}
                  maintenance={maintenance}
                  maintenanceId={maintenanceId}
                  fetchPage={fetchPage}
                  page={page}
                />
              </div>
            </Box>
          )}

          {displayAllMaintenances && (
            <Button>
              <FAIcon
                className="flex items-center"
                collection="fal"
                icon="file-alt"
                size="large"
              />
              <Box className="flex items-start ml-2 flex-col relative">
                <strong className="text-base text-left">{t('entityMaintenanceName')}</strong>
                <strong className="text-xs">{auth.interface._company.name}</strong>
              </Box>
            </Button>
          )}

          <Box className="flex items-center justify-end pb-[2px]">
            {count > 1 && (
              <>
                <Box className="text-gray-400">
                  <p>{elementsLength} résultats</p>
                </Box>
                <Pagination
                  count={count}
                  page={page}
                  onChange={(e, p) => fetchPage(p)}
                  color="primary"
                />
              </>
            )}
          </Box>
        </header>
      </Portal>

      {ticketId && (
        <Ticket
          on_click_left_arrow={handle_click_left_arrow}
          on_click_right_arrow={handle_click_right_arrow}
          period_ids={period_ids}
          fromTimeline
          isDialog
          defaultOpenView
          selectedId={ticketId}
          afterDialogClose={() => setTicketId(null)}
        >
          <> </>
        </Ticket>
      )}

      <ConfirmDialog
        text={
          confirmUpdateOpen
            ? t('editPeriodFromTo', {
                from: dateToLocalFormat(confirmUpdateOpen?.start_time),
                to: dateToLocalFormat(confirmUpdateOpen?.end_time)
              })
            : ''
        }
        open={confirmUpdateOpen}
        onClose={(e) => [setSelectedItem([]), setConfirmUpdateOpen(false)]}
        onConfirm={() => updateItem()}
      />

      <Box className="h-full relative overflow-y-scroll">
        <BlurredProgress
          in={isLoading}
          border={ticketView}
        />
        <Box className="absolute top-0 left-0 w-full h-full">
          {!elements ||
            (!elements?.length && (
              <Empty
                page
                icon={icon}
                translations={translations}
              />
            ))}
          {!!elements?.length && !!visibleTimeStart && !!visibleTimeEnd && (
            <ReactTimeline
              lineHeight={80}
              groups={groups}
              items={items}
              resizeDetector={{ addListener, removeListener }}
              selected={selectedItem}
              defaultTimeStart={visibleTimeStart}
              defaultTimeEnd={visibleTimeEnd}
              sidebarWidth={ticketView ? 0 : 260}
              canMove={role.permission('maintenances', 'dates')}
              canResize={role.permission('maintenances', 'dates')}
              onItemMove={handleItemMove}
              onItemResize={handleItemResize}
              stackItems={true}
              itemTouchSendsClick={false}
              itemsSorted={false}
              itemRenderer={({ item, itemContext, getItemProps }) => (
                <TimelineElement
                  item={item}
                  itemContext={itemContext}
                  getItemProps={getItemProps}
                  setTicketId={setTicketId}
                  set_period={set_period}
                />
              )}
              groupRenderer={({ group }) => {
                const maintenance = group.maintenance;
                const element = {
                  ...(maintenance._location || maintenance._equipment),
                  maintenance: pick(maintenance, ['_intervener', '_client', 'config', '_id'])
                };
                if (group.maintenance.type === '_equipment') {
                  return (
                    <Equipment
                      stopPropagation
                      onClickPreview={() => {}}
                      childrenId={element._id}
                      styleChildren={{ height: '100%' }}
                    >
                      <Preview
                        variant
                        element={element}
                        previewComponent={EquipmentPreview}
                        stylePreview={{ padding: '6px 8px 6px 12px' }}
                        previewPayload={{
                          timeline: true
                        }}
                      />
                    </Equipment>
                  );
                } else {
                  return (
                    <Location
                      stopPropagation
                      onClickPreview={() => {}}
                      childrenId={element._id}
                      styleChildren={{ height: '100%' }}
                    >
                      <Preview
                        variant
                        element={element}
                        previewComponent={LocationPreview}
                        stylePreview={{ padding: '6px 8px 6px 12px' }}
                        previewPayload={{
                          timeline: true
                        }}
                      />
                    </Location>
                  );
                }
              }}
              className={classes.timeline}
            >
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      !ticketView && (
                        <Box
                          {...getRootProps()}
                          className="flex items-center justify-between bg-white"
                        >
                          <div className="flex items-center ml-1">
                            <Tooltip
                              placement="right"
                              title={t('menu')}
                            >
                              <button
                                onClick={() => setOpenMenu(!openMenu)}
                                className="h-8 w-8 hover:bg-gray-100 rounded-full"
                              >
                                <FAIcon
                                  icon="ellipsis-vertical"
                                  collection="fas"
                                  size="small"
                                />
                              </button>
                            </Tooltip>
                          </div>
                          {openMenu && (
                            <div
                              ref={ref}
                              className="z-50 bg-white rounded-xl p-2 w-48 absolute left-8 top-2 flex flex-col items-center gap-1 shadow-md"
                            >
                              <button
                                onClick={() => setFullscreen(!fullscreen)}
                                className="flex items-center gap-2 w-full p-2 hover:bg-gray-100 rounded-xl text-left"
                              >
                                {!fullscreen ? (
                                  <Fullscreen className="text-slate-500" />
                                ) : (
                                  <FullscreenExit className="text-slate-500" />
                                )}
                                {!fullscreen ? t('fullScreenMode') : t('quitFullScreen')}
                              </button>
                              <button
                                onClick={() => fetchPage(page)}
                                className="flex items-center gap-2 w-full p-2 hover:bg-gray-100 rounded-xl text-left"
                              >
                                {<Refresh className="text-slate-500" />}
                                {t('refresh')}
                              </button>
                              <button
                                onClick={() => handleExportMaintenanceLate()}
                                className="flex items-center gap-2 w-full p-2 hover:bg-gray-100 rounded-xl text-left"
                              >
                                {isDownloading ? (
                                  <FAIcon
                                    icon="spinner-third"
                                    collection="fad"
                                    size="small"
                                    className="animate-spin text-slate-500"
                                  />
                                ) : (
                                  <FAIcon
                                    icon="download"
                                    collection="far"
                                    size="small"
                                    className="text-slate-500"
                                  />
                                )}
                                {t('export')}
                              </button>
                              {
                                // Affiche le bouton uniquement pour Kingfisher
                                auth?.interface?._company?._id === KINGFISHER_DEMO_ACCOUNT_ID && (
                                  <button
                                    onClick={() => handle_relance_presta()}
                                    className="flex items-center gap-2 w-full p-2 hover:bg-gray-100 rounded-xl text-left"
                                  >
                                    {
                                      <FAIcon
                                        icon="bell-on"
                                        collection="fal"
                                        className="text-slate-500"
                                        size="small"
                                      />
                                    }
                                    {t('relance-date-reports')}
                                  </button>
                                )
                              }
                            </div>
                          )}
                          <div>
                            <Tooltip
                              placement="left"
                              title={
                                <div className="text-xs">
                                  <strong>SHIFT + {t('mouseWheel')}:</strong> <span>{t('scrollHorizontally')}</span>
                                  <br />
                                  <strong>ALT +{t('mouseWheel')}:</strong> <span>{t('zoom')}</span>
                                  <br />
                                  <strong>{t('click', { count: 1 })}:</strong> <span>{t('editThisPeriod')}</span>
                                  <br />
                                  <strong>{t('click', { count: 2 })}:</strong> <span>{t('openIntervention')}</span>
                                  <br />
                                </div>
                              }
                            >
                              <IconButton size={'small'}>{<InfoRounded />}</IconButton>
                            </Tooltip>
                          </div>
                        </Box>
                      )
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
              </TimelineHeaders>
              <TimelineMarkers>
                <TodayMarker>
                  {({ styles, date }) => <Box style={{ ...styles, backgroundColor: '#959ba1', zIndex: 4000 }} />}
                </TodayMarker>
              </TimelineMarkers>
            </ReactTimeline>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export const PeriodsMenu = ({ maintenance }) => {
  const [anchorEL, setAnchorEl] = useState(null);
  const openMainMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(e.currentTarget)];
  const closeMainMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(null)];

  const [anchorElPeriods, setAnchorElPeriods] = useState(null);
  const [anchorElIntervener, setAnchorElIntervener] = useState(null);
  const [anchorElDatePicker, setAnchorElDatePicker] = useState(null);
  const [showDataPickerPopover, setShowDataPickerPopover] = useState(false);

  const openPeriodsMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorElPeriods(e.currentTarget)];
  const closePeriodsMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorElPeriods(null)];
  const openIntervenerMenu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorElIntervener(e.currentTarget)];
  const closeIntervenerMenu = (e) => [e?.preventDefault(), e?.stopPropagation(), setAnchorElIntervener(null)];
  const openDatePickerEndPeriodicity = (e) => [
    e?.preventDefault(),
    e?.stopPropagation(),
    setAnchorElDatePicker(e?.currentTarget),
    setShowDataPickerPopover(true)
  ];
  const closeDatePickerEndPeriodicity = (e) => [
    e?.preventDefault(),
    e?.stopPropagation(),
    setAnchorElDatePicker(null),
    setShowDataPickerPopover(false)
  ];

  const k1 = useKeys();
  const { t } = useTranslation();

  const [period, setPeriod] = useState(maintenance.config.type);
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();

  const config = useConfiguration();
  const { permission } = useRole();

  const canUpdateMaintenance = permission('maintenances', 'update');

  const savePeriodicity = () => {
    dispatch(updateElementPeriod, { type: 'config', value: { type: period } }, {}, { id: maintenance._id });
  };

  const periods = useSelector((state) => state['periods']);

  const periodHasStopTimeDefined = periods?.db[maintenance?._id]?.stop_time;

  const cancelEndPeriodicity = () => {
    dispatch(
      updateElementPeriod,
      { type: 'times', value: { stop_time: null } },
      {
        onSuccess: () => notify.success(),
        onError: () => notify.error()
      },
      { id: maintenance._id }
    );
  };

  if (!canUpdateMaintenance) return null;

  return (
    <>
      <IconButton
        aria-haspopup="true"
        style={{ position: 'absolute', right: '7px', padding: 0 }}
        onClick={openMainMenu}
        onMouseOver={(e) => [e.preventDefault(), e.stopPropagation()]}
        onMouseEnter={(e) => [e.preventDefault(), e.stopPropagation()]}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEL}
        transitionDuration={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorEL}
        onClose={closeMainMenu}
        keepMounted
      >
        <MenuItem
          dense
          onClick={openPeriodsMenu}
        >
          <ListItemIcon>
            <FAIcon
              collection="fas"
              icon="infinity"
              size="small"
            />
          </ListItemIcon>
          <ListItemText primary={t('changeThePeriodicity')} />
          <ListItemSecondaryAction>
            <ArrowRightIcon
              style={{ margin: 0 }}
              onClick={openPeriodsMenu}
            />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem
          dense
          onClick={openIntervenerMenu}
        >
          <ListItemIcon>
            <FAIcon
              collection="fas"
              icon="hard-hat"
              size="small"
            />
          </ListItemIcon>
          <ListItemText primary={t("Changer l'intervenant")} />
          <ListItemSecondaryAction>
            <ArrowRightIcon
              style={{ margin: 0 }}
              onClick={openIntervenerMenu}
            />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem
          dense
          onClick={periodHasStopTimeDefined ? cancelEndPeriodicity : openDatePickerEndPeriodicity}
        >
          <ListItemIcon>
            <FAIcon
              collection="fas"
              icon="hourglass-end"
              size="small"
            />
          </ListItemIcon>
          <ListItemText primary={periodHasStopTimeDefined ? t('cancelEndThePeriodicity') : t('endThePeriodicity')} />
        </MenuItem>
      </Menu>
      <Menu
        transitionDuration={0}
        anchorEl={anchorElPeriods}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorElPeriods}
        onClose={closePeriodsMenu}
      >
        <Select
          fullWidth
          variant="outlined"
          value={period}
          onChange={(e) => [e.stopPropagation(), e.preventDefault(), setPeriod(e.target.value)]}
          disabled={requestStatus === 'loading'}
        >
          {Object.keys(periodicities)
            .filter((type) => (!config.isBlachere && !['reduit', 'complet'].includes(type)) || config.isBlachere)
            .map((p, i) => (
              <MenuItem
                key={k1(i)}
                value={p}
              >
                {t(periodicities[p])}
              </MenuItem>
            ))}
        </Select>

        <Button
          endIcon={<SaveIcon />}
          variant="outlined"
          fullWidth
          style={{
            marginTop: 6,
            justifyContent: 'space-between',
            display: 'flex',
            borderRadius: 8
          }}
          onClick={(e) => [e.stopPropagation(), e.preventDefault(), savePeriodicity()]}
          disabled={requestStatus === 'loading'}
        >
          {t('saveThePeriodicity')}
        </Button>
      </Menu>

      <ChangeIntervenerMenu
        anchorElIntervener={anchorElIntervener}
        closeIntervenerMenu={closeIntervenerMenu}
        intervener={maintenance?._intervener || null}
        maintenanceId={maintenance?._id}
      />

      <EndPeriodicity
        period_id={maintenance?._id}
        showDataPickerPopover={showDataPickerPopover}
        anchorElDatePicker={anchorElDatePicker}
        closeDatePickerEndPeriodicity={closeDatePickerEndPeriodicity}
      />
    </>
  );
};

function ChangeIntervenerMenu({ anchorElIntervener, closeIntervenerMenu, intervener, maintenanceId }) {
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  return (
    <>
      <Popover
        open={!!anchorElIntervener}
        onClose={closeIntervenerMenu}
        transitionDuration={0}
        anchorEl={anchorElIntervener}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Form
          initialValues={{ intervener }}
          afterChange={(data) => [
            dispatch(
              updateElement,
              { type: '_intervener', value: data?.intervener?._id },
              {
                onSuccess: () => notify.success(),
                onError: () => notify.error()
              },
              { id: maintenanceId }
            ),
            closeIntervenerMenu()
          ]}
          style={{
            width: 500,
            height: 500,
            position: 'relative'
          }}
        >
          <Intervener
            stopPropagation
            pickerField="intervener"
            picker
            pickerUniq
            form
            elementsPicker={intervener?._id ? [intervener._id] : []}
            disableGutters
            type="contractor"
          />
        </Form>
      </Popover>
    </>
  );
}

function EndPeriodicity({ period_id, anchorElDatePicker, closeDatePickerEndPeriodicity, showDataPickerPopover }) {
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const dbPeriods = useSelector((state) => state['periods']).db;

  const endDatePeriodicity = dbPeriods[period_id]?.stop_time;

  return (
    <>
      <CalendarPopover
        closeOnChange
        anchorEl={anchorElDatePicker}
        open={showDataPickerPopover}
        onClose={closeDatePickerEndPeriodicity}
        date={endDatePeriodicity || null}
        onChange={({ date }) => [
          dispatch(
            updateElementPeriod,
            {
              type: 'times',
              value: {
                stop_time: date
              }
            },
            {
              onSuccess: () => notify.success(),
              onError: () => notify.error()
            },
            { id: period_id }
          ),
          closeDatePickerEndPeriodicity()
        ]}
      />
    </>
  );
}

function ChangePeriods({ openChangePeriods, setOpenChangePeriods, maintenance, maintenanceId, fetchPage, page }) {
  const { t } = useTranslation();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();

  const onClose = () => setOpenChangePeriods(false);

  const handleSubmit = (data) => {
    let _equipment_locations = data._equipments
      .map((equipment) => equipment?._location?._id || null)
      .filter((e) => Boolean(e));
    _equipment_locations = [..._equipment_locations, ...data._equipment_locations];
    // TODO: deduplicate _equipment_locations

    const formattedData = {
      ...{ _locations: (data._locations || []).map((l) => l?._id) },
      ...{ _equipments: (data._equipments || []).map((e) => e?._id) },
      ...{ _equipment_locations }
    };

    dispatch(
      updateMaintenanceLocationsOrEquipments,
      formattedData,
      {
        onSuccess: () => [notify.success(), onClose()],
        onError: () => notify.error()
      },
      { id: maintenanceId }
    ).then(() => fetchPage(page));
  };

  return (
    <Dialog
      icon="edit"
      title={t('changePeriods')}
      width="3xl"
      isOpen={openChangePeriods}
      onClose={onClose}
    >
      <div className="mt-4">
        <Form
          disabled={requestStatus === 'loading'}
          initialValues={{
            _equipment_locations: maintenance?._equipment_locations || [],
            _locations: maintenance?._locations || [],
            _equipments: maintenance?._equipments || []
          }}
          onSubmit={(data) => handleSubmit(data)}
        >
          <div className="mb-1 pt-2 overflow-auto max-h-[500px] shadow-sm">
            <EntityX
              path="_locations"
              entityName="locations"
              entity={Location}
              placeholder={t('locations')}
              showAll
            />
          </div>
          <div className="mb-1 pt-2 overflow-auto max-h-[500px] shadow-sm">
            <EntityX
              path="_equipments"
              entityName="equipments"
              entity={Equipment}
              placeholder={t('equipmentsTitle')}
              showAll
            />
          </div>
          <div className="mt-2 flex justify-end">
            <Submit>
              <Button>{t('save2')}</Button>
            </Submit>
          </div>
        </Form>
      </div>
    </Dialog>
  );
}
