import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function NewReportFilter({ path }) {
  const { value, setValue } = useField(path);
  const { t } = useTranslation();

  const updateFieldValue = (field, newValue) => {
    setValue({ ...value, [field]: newValue });
  };

  const handleRadioChange = (field) => (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    updateFieldValue(field, {
      with: selectedValue !== 2,
      without: selectedValue !== 1
    });
  };

  const getDefaultRadioValue = (field) => {
    const fieldValue = value[field];
    if (fieldValue.with && fieldValue.without) return '0';
    if (fieldValue.with) return '1';
    if (fieldValue.without) return '2';
    return '0';
  };

  const renderRadioGroup = (label, field) => (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        style={{ flexDirection: 'row', alignItems: 'center', marginBottom: label ? 10 : 0 }}
        value={getDefaultRadioValue(field)}
        onChange={handleRadioChange(field)}
      >
        <FormControlLabel
          value="0"
          control={<Radio />}
          label={t('withOrWithout')}
        />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label={t('with')}
        />
        <FormControlLabel
          value="2"
          control={<Radio />}
          label={t('without')}
        />
      </RadioGroup>
    </FormControl>
  );

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {renderRadioGroup(null, 'ir')}
      {value.ir.with && !value.ir.without && renderRadioGroup(t('Reserves'), 'reserve')}
    </Box>
  );
}
