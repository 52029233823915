import { Box, useTheme } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { useMemo } from 'react';
import { getRandomColor } from 'constants/colors';

export default function BarsChart({ datasets, labels, datasetsLabels, colors = [], formatYAxis }) {
  const theme = useTheme();
  const memoizedDatasets = useMemo(() => {
    return datasets.map((datapoints, i) => {
      const color = colors[i] || colors[i] || getRandomColor();

      return {
        label: datasetsLabels[i],
        data: datapoints,
        backgroundColor: color
      };
    });
  }, [datasets, datasetsLabels]);

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Bar
        data={{
          labels,
          datasets: memoizedDatasets
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            }
          },
          responsive: true,
          scales: {
            xAxes: {
              stacked: true,
              ticks: {
                fontColor: theme.palette.primary.main
              }
            },
            yAxes: {
              stacked: true,
              ticks: {
                fontColor: theme.palette.primary.main,
                callback: formatYAxis
              }
            }
          }
        }}
      />
    </Box>
  );
}
