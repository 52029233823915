import React from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';

const OpenValidatedContract = ({ _id, isLoading, history, config }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box marginTop={'10px'}>
      <Button
        disabled={isLoading}
        onClick={() => history.push(`/tickets/${_id}`)}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'files'}
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classes.viewAction}
      >
        {t('openIntervention')}
      </Button>
    </Box>
  );
};

export default OpenValidatedContract;
