import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  Button,
  Typography,
  Box,
  InputBase
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  ArrowRight as ArrowRightIcon,
  CloudDownload as CloudDownloadIcon,
  Refresh,
  CloudDownload
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { useState, useMemo } from 'react';
import { useEntity } from 'contexts/entities/entityContext';

import useStyles from './EntityMenu.styles';
import { Form, Submit, useField } from 'frmx';
import { useRole } from 'hooks/useRole';
import useKeys from '@flowsn4ke/usekeys';
import EntityDialog from 'components/dialogs/EntityDialog';
import entities from 'config/entities';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { useIsBelowSm } from 'hooks/useMQ';
import axios from 'axios';
import { apiBaseURL } from 'index';
import useNotifications from 'hooks/useNotifications';
import { saveAs } from 'file-saver';

export default function EntityMenu({ fetchElements, getXhrFilters, search }) {
  const { exportColumns } = useEntity();
  const classes = useStyles();

  const [anchorElMain, setAnchorElMain] = useState(null);
  const [anchorElExport, setAnchorElExport] = useState(null);

  const openMainMenu = (e) => setAnchorElMain(e.currentTarget);
  const closeMainMenu = (e) => setAnchorElMain(null);
  const openExportMenu = (e) => setAnchorElExport(e.currentTarget);
  const closeExportMenu = (e) => setAnchorElExport(null);

  return (
    <>
      <IconButton
        aria-haspopup="true"
        className={classes.entityMenu}
        onClick={openMainMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <MainMenu
        fetchElements={fetchElements}
        anchorElMain={anchorElMain}
        closeMainMenu={closeMainMenu}
        classes={classes}
        exportColumns={exportColumns}
        openExportMenu={openExportMenu}
      />

      <ExportMenu
        search={search}
        getXhrFilters={getXhrFilters}
        closeExportMenu={() => [closeExportMenu()]}
        closeMainMenu={closeMainMenu}
        anchorElExport={anchorElExport}
        classes={classes}
        exportColumns={exportColumns}
      />
    </>
  );
}

function MainMenu({ anchorElMain, closeMainMenu, exportColumns, openExportMenu, fetchElements, classes }) {
  const [trashIsOpen, setTrashIsOpen] = useState(false);
  const { t } = useTranslation();

  const role = useRole();
  const { flushElements, listId, entity, entityMenus, elements, trash } = useEntity();
  const syncDispatch = useDispatch();

  const elementsLength = elements?.count || 0;

  const k = useKeys();

  return (
    <>
      <Menu
        anchorEl={anchorElMain}
        transitionDuration={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorElMain}
        onClose={closeMainMenu}
        MenuListProps={{ className: classes.moreMenu }}
        keepMounted
      >
        <MenuItem
          dense
          onClick={() => {
            syncDispatch(flushElements({ listId, page: false }));
            fetchElements(null, true);
            closeMainMenu();
          }}
          className={classes.moreMenuElement}
        >
          <ListItemIcon>
            <Refresh />
          </ListItemIcon>
          <ListItemText primary={t('refresh')} />
        </MenuItem>

        {/* {!!tables.length && !defaultTable && <MenuItem dense onClick={() => {
        isListTable ?
          setIsListTable(false)
          : setIsListTable(true)

        closeMainMenu()
      }} className={classes.moreMenuElement} >
        {!isListTable && <>
          <ListItemIcon>
            <ViewColumn />
          </ListItemIcon>
          <ListItemText primary={t('screenColumns')} />
        </>
        }
        {isListTable && <>
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary={t('screenList')} />
        </>
        }
      </MenuItem>} */}

        {(entityMenus || []).map((menu, i) => (
          <MenuItem
            key={k(i)}
            dense
            onClick={() => {
              menu.action();
              closeMainMenu();
            }}
            className={classes.moreMenuElement}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={t(menu.label)} />
          </MenuItem>
        ))}

        {entities[entity]?.exportEndpoint && !!exportColumns?.length && role.permission('bobdesk', 'export') && (
          <MenuItem
            dense
            onClick={openExportMenu}
            className={classes.moreMenuElement}
          >
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText primary={t('ExportXEls', { count: elementsLength })} />
            <ListItemSecondaryAction>
              <ArrowRightIcon
                style={{ margin: 0 }}
                onClick={openExportMenu}
              />
            </ListItemSecondaryAction>
          </MenuItem>
        )}

        {!!entities[entity].entity && !trash && (
          <MenuItem
            dense
            onClick={() => [setTrashIsOpen(true), closeMainMenu()]}
            className={classes.moreMenuElement}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={t('trash')} />
          </MenuItem>
        )}

        <EntityDialog
          open={trashIsOpen}
          onClose={() => setTrashIsOpen(false)}
          trash
          entity={entity}
        />
      </Menu>
    </>
  );
}

function ExportMenu({ closeExportMenu, closeMainMenu, anchorElExport, classes, search, exportColumns, getXhrFilters }) {
  const { entity, entityFieldsSlice } = useEntity();
  const notify = useNotifications();
  const [requestStatus, setRequestStatus] = useState('idle');

  let customFields = useSelector((store) => store.fieldSections);

  const exportColumnsWithCustomFields = useMemo(() => {
    if (entityFieldsSlice) {
      customFields = customFields[entityFieldsSlice];
      customFields = customFields.reduce((acc, curr) => [...acc, ...curr.fields], []);
      customFields = customFields.map((f) => ({ label: f.label, key: f._id }));
      return exportColumns.concat(customFields);
    } else {
      return exportColumns;
    }
  }, [entityFieldsSlice, exportColumns]);

  const initialValues = exportColumnsWithCustomFields.reduce((a, column) => ({ ...a, [column.key]: true }), {});

  const isLoading = requestStatus === 'loading';

  const exportFn = (form) => {
    setRequestStatus('loading');

    const fields = Object.keys(form).filter((key) => !!form[key]);
    axios
      .post(
        `${apiBaseURL}/miscs/export/${entities[entity].exportEndpoint}`,
        { fields, filters: getXhrFilters(), search },
        { responseType: 'blob' }
      )
      .then((res) => {
        const csvExport = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        });
        saveAs(csvExport, `${entity}.xlsx`);
        setRequestStatus('success');
        closeExportMenu();
        closeMainMenu();
        notify.success('Fichier téléchargé!');
      })
      .catch((err) => {
        setRequestStatus('error');
        notify.error();
      });
  };

  const isBelowSm = useIsBelowSm();

  return (
    <>
      <Menu
        transitionDuration={0}
        anchorEl={anchorElExport}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!anchorElExport}
        onClose={() => [closeExportMenu(), closeMainMenu()]}
        PaperProps={{ style: { width: isBelowSm ? '100%' : '20%' } }}
        MenuListProps={{ className: classes.moreMenu }}
        className={classes.moreMenu}
      >
        <Form
          initialValues={initialValues}
          onSubmit={exportFn}
        >
          <ExportForm
            isLoading={isLoading}
            classes={classes}
            exportColumns={exportColumnsWithCustomFields}
          />
        </Form>
      </Menu>
    </>
  );
}

const ExportForm = ({ exportColumns, classes, isLoading }) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <Box>
      <InputBase
        style={{ padding: '4px 16px' }}
        placeholder={t('findValue')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box
        maxHeight="360px"
        overflow="auto"
      >
        {exportColumns
          .filter((column) => t(column.label)?.toLowerCase().includes(search?.toLowerCase()))
          .map((column, i) => (
            <Column
              key={k(i)}
              column={column}
              classes={classes}
            />
          ))}
      </Box>
      <Submit>
        <Button
          disabled={isLoading}
          endIcon={
            isLoading ? (
              <FAIcon
                icon="spinner"
                className="fa-spin"
                collection="fas"
                size="medium"
              />
            ) : (
              <CloudDownload />
            )
          }
          variant="outlined"
          style={{
            marginTop: 6,
            justifyContent: 'space-between',
            display: 'flex',
            borderRadius: 8
          }}
          fullWidth
        >
          {t('export')}
        </Button>
      </Submit>
    </Box>
  );
};

const Column = ({ classes, column }) => {
  const { value: selected, setValue: setSelected } = useField(column.key);
  const { t } = useTranslation();

  return (
    <MenuItem
      style={{ paddingRight: 32 }}
      onClick={() => setSelected(!selected)}
      dense
      className={classes.moreMenuElement}
    >
      <ListItemText
        title={t(column.label)}
        primary={
          <Typography
            component="span"
            className={classes.moreMenuText}
            style={{ fontWeight: selected ? 700 : 400 }}
          >
            {t(column.label)}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Checkbox
          color={'primary'}
          checked={selected}
          onChange={(e) => setSelected(e.target.checked)}
        />
      </ListItemSecondaryAction>
    </MenuItem>
  );
};
