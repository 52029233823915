import { add, formatISO, parseISO, format, isDate, differenceInCalendarDays, intervalToDuration } from 'date-fns';
import { locales } from 'i18n';
//import { isDate } from "lodash"

export const dateToInput = (date, representation = 'date') => {
  try {
    return formatISO(!isDate(date) ? new Date(date) : date, { representation });
  } catch (err) {
    console.log(err);
  }
};

export const dateTimeToInput = (date) => {
  try {
    return format(new Date(date), "yyyy-MM-dd'T'hh:mm", { awareOfUnicodeTokens: true });
  } catch (err) {
    console.log(err);
  }
};

export const dateFromInput = (date) => {
  try {
    return parseISO(date);
  } catch (err) {
    console.log(err);
  }
};

export const addToDate = (date, duration, durationType) => {
  try {
    return add(date, { [durationType]: duration });
  } catch (err) {
    console.log(err);
  }
};

export const addToAndFormatDate = (date, duration, durationType) => {
  try {
    return dateToInput(addToDate(!isDate(date) ? new Date(date) : date, duration, durationType));
  } catch (err) {
    console.log(err);
  }
};

export const dateToLocalFormat = function (date, formatStr = 'PPP') {
  try {
    return format(!isDate(date) ? new Date(date) : date, formatStr, {
      locale: locales[window.__localeId__]
    });
  } catch (err) {
    console.log('Date parsing error: ', err);
  }
};

export const count_days_between_two_dates = (date1, date2) => {
  if (typeof date1 === 'undefined' || typeof date2 === 'undefined') return 0;

  return differenceInCalendarDays(new Date(date1), new Date(date2));
};

export default function compute_time_ago(at) {
  try {
    const rtf1 = new Intl.RelativeTimeFormat(locales[window.__localeId__], { style: 'short' });
    const time_elapsed = intervalToDuration({ start: new Date(at), end: new Date() });

    const seconds = time_elapsed.seconds;
    const minutes = time_elapsed.minutes;
    const days = time_elapsed.days;
    const hours = time_elapsed.hours;
    const months = time_elapsed.months;
    const years = time_elapsed.years;

    const duration =
      (years && -years) ||
      (months && -months) ||
      (days && -days) ||
      (hours && -hours) ||
      (minutes && -minutes) ||
      (seconds && -seconds) ||
      0;
    const unit =
      (years && 'year') ||
      (months && 'month') ||
      (days && 'day') ||
      (hours && 'hour') ||
      (minutes && 'minute') ||
      'second';

    return rtf1.format(duration, unit);
  } catch (err) {
    console.error(err);
    return '';
  }
}
