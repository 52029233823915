import { Box, Grid, Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import useKeys from '@flowsn4ke/usekeys';
import { useMemo } from 'react';
import randomFlatColor from 'random-flat-colors';
import { useIsBelowUp } from 'hooks/useMQ';

export default function Camembert({ datasets, labels, colors = [], title }) {
  colors = useMemo(() => {
    const additionalColors = labels.map(() => randomFlatColor());
    return [...colors, ...additionalColors];
  }, [labels]);
  const k = useKeys();
  const isBelowXxl = useIsBelowUp(1280);
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={6}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box>
          <Doughnut
            style={{
              width: isBelowXxl ? '250px' : '300px',
              height: isBelowXxl ? '250px' : '300px'
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false
                }
              },
              layout: {
                padding: 30
              }
            }}
            data={{
              labels,
              datasets: [
                {
                  label: title,
                  data: datasets,
                  backgroundColor: colors.map((c) => c),
                  hoverOffset: 1
                }
              ]
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box style={{ minWidth: '80%' }}>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {labels.slice(0, 6).map((label, i) => (
              <li
                key={k(i)}
                style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
              >
                <span>
                  <svg
                    width="14px"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="2"
                      width="10"
                      height="10"
                      fill={colors[i]}
                    />
                  </svg>
                </span>
                <Typography
                  component="span"
                  style={{ marginLeft: 7 }}
                >
                  {label}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
}
