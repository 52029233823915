import { ChevronRight } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function MenuItem({ icon, label, close = () => {}, onClick = () => {}, className, hasSubMenu }) {
  const { t } = useTranslation();

  return (
    <div
      onClick={(event) => [onClick(event), close()]}
      className={classNames('rounded hover:bg-gray-100 flex cursor-pointer items-center py-2 pr-8 relative', className)}
    >
      <FAIcon
        collection="fas"
        icon={icon}
        size="small"
        className="mx-1"
      />
      <span className="ml-1 text-sm">{t(label)}</span>
      {hasSubMenu && <ChevronRight className="absolute right-0" />}
    </div>
  );
}
