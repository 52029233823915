import UserInfo from 'settings/UserInfo';
import CompanyInfo from 'settings/CompanyInfo';
import ChangePassword from 'settings/ChangePassword';
import Activity from 'settings/Activity';
import TicketConfiguration from 'settings/TicketConfiguration';
import LocationsConfiguration from 'settings/LocationsConfiguration';
import ClientsConfiguration from 'settings/ClientsConfiguration';
import PublicConfiguration from 'settings/PublicConfiguration';
import ContractorsConfiguration from 'settings/ContractorsConfiguration';
import AccountingConfiguration from 'settings/AccountingConfiguration';
import SupportConfiguration from 'settings/SupportConfiguration';
import UsersConfiguration from 'settings/UsersConfiguration';
import EquipmentConfiguration from 'settings/EquipmentConfiguration';
import Jobs from 'settings/Jobs';
import Permissions from 'settings/Permissions';
import Reports from 'settings/Reports';
import CustomField from 'settings/CustomField';
import Features from 'settings/Features';
import MarketplaceActivation from 'settings/MarketplaceActivation';
import MarketplaceDetails from 'settings/MarketplaceDetails';
import MarketplacePricing from 'settings/MarketplacePricing';
import BudgetsConfiguration from 'settings/BudgetsConfiguration';
import Avatar from 'components/Avatar';
import bobdepannage from 'images/bobdepannage.png';
import ApiTokenConfiguration from 'settings/ApiTokenConfiguration';

import { additionalCustomFielsMenus, getMenus } from 'config/menus';

// Why 158 ? All permissions manageable by the front end total 107, and there are 51 non-manageable permissions (meters (13), inventory (14), leases (5), contacts (5), stocks (14)).
// 107 + 51 = 158
const MINIMUM_NUMBER_PERMISSIONS_TO_SHOW_TOKEN_API = 158;

export const getSettingsMenus = (_interface, role) => {
  let totalPermissionsCount = 0;
  const permissions = _interface._role.permissions;

  for (const key in permissions) totalPermissionsCount += permissions[key]?.length;

  const showTokenApi =
    totalPermissionsCount >= MINIMUM_NUMBER_PERMISSIONS_TO_SHOW_TOKEN_API || // show to user with more than 107 permissions
    _interface._role.key === 'root_admin' || // show to "super admin" (client)
    _interface._role.key === 'root_contractor'; // show to "super admin" (contractor)

  return [
    {
      component: (
        <Avatar
          entity={_interface._company}
          size="xsmall"
        />
      ),
      label: _interface._company.name,
      key: 'company-settings',
      show: role.permission('bobdesk', 'company')
    },
    {
      icon: 'book-spells',
      label: 'marketplace',
      key: 'marketplace-settings',
      show: _interface.isContractor && role.permission('bobdesk', 'configurations')
    },
    {
      icon: 'id-card',
      label: 'myAccount',
      key: 'account-settings',
      show: true
    },
    {
      icon: 'shapes',
      label: 'features',
      key: 'features-settings',
      show: role.permission('bobdesk', 'configurations')
    },
    {
      icon: 'cogs',
      label: 'settings',
      key: 'configurations-settings',
      show: role.permission('bobdesk', 'configurations')
    },
    {
      icon: 'key',
      label: 'roles',
      key: 'roles-settings',
      show: role.permission('bobdesk', 'roles')
    },
    {
      icon: 'toolbox',
      label: 'jobs',
      key: 'jobs-settings',
      show: role.permission('bobdesk', 'jobs')
    },
    {
      icon: 'file-chart-pie',
      label: 'reports',
      key: 'reports-settings',
      show: role.permission('bobdesk', 'reports')
    },
    {
      icon: 'asterisk',
      label: 'fields',
      key: 'customizable-fields-settings',
      show: role.permission('bobdesk', 'fields')
    },
    // APi_key SettingsMenu
    {
      icon: 'key',
      label: 'tokenApi',
      key: 'tokenApi-settings',
      show: showTokenApi
    }
  ].filter((e) => e.show);
};

export const sections = (_interface) =>
  [
    {
      icon: 'id-badge',
      key: 'info-section',
      label: 'myInfo',
      menuKey: 'account-settings',
      component: UserInfo,
      keywords: [],
      show: true
    },
    {
      icon: 'fingerprint',
      key: 'password-section',
      label: 'password',
      menuKey: 'account-settings',
      component: ChangePassword,
      keywords: [],
      show: true
    },
    {
      icon: 'circle',
      key: 'company-info-section',
      menuKey: 'company-settings',
      label: _interface._company.name,
      component: CompanyInfo,
      keywords: [],
      show: true
    },
    {
      icon: 'shapes',
      key: 'features-section',
      menuKey: 'features-settings',
      label: 'functionalities',
      component: Features,
      keywords: [],
      show: true
    },
    {
      icon: 'hard-hat',
      label: 'myActivity',
      menuKey: 'company-settings',
      component: Activity,
      keywords: [],
      show: _interface.isContractor
    },
    {
      icon: 'files',
      key: 'ticket-section',
      menuKey: 'configurations-settings',
      label: 'interventions',
      component: TicketConfiguration,
      keywords: [],
      show: true
    },
    {
      icon: 'map-marker-alt',
      key: 'locations-section',
      menuKey: 'configurations-settings',
      label: 'locations',
      component: LocationsConfiguration,
      keywords: [],
      show: true
    },
    {
      icon: 'house-building',
      key: 'clients-section',
      menuKey: 'configurations-settings',
      label: 'clientsTitle',
      component: ClientsConfiguration,
      keywords: [],
      show: !_interface.isClient
    },
    {
      icon: 'user-hard-hat',
      key: 'contractors-section',
      menuKey: 'configurations-settings',
      label: 'intervenersTitle',
      component: ContractorsConfiguration,
      keywords: [],
      show: true
    },
    {
      icon: 'user',
      key: 'users-section',
      menuKey: 'configurations-settings',
      label: 'usersTitle',
      component: UsersConfiguration,
      keywords: [],
      show: true
    },
    {
      icon: 'wrench-simple',
      key: 'equipment-section',
      menuKey: 'configurations-settings',
      label: 'menuItemEquipments',
      component: EquipmentConfiguration,
      keywords: [],
      show: _interface._company._configuration.feature.equipment
    },
    {
      icon: 'user-secret',
      key: 'public-section',
      menuKey: 'configurations-settings',
      label: 'publicInterfaceTitle',
      component: PublicConfiguration,
      keywords: [],
      show: _interface._company._configuration.feature.ticket_public
    },
    {
      icon: 'wallet',
      key: 'budgets-section',
      menuKey: 'configurations-settings',
      label: 'budgetsTitle',
      component: BudgetsConfiguration,
      keywords: [],
      show: _interface._company._configuration.feature.budgets
    },
    {
      icon: 'calculator',
      key: 'accounting-section',
      menuKey: 'configurations-settings',
      label: 'accountingTitle',
      component: AccountingConfiguration,
      keywords: [],
      show: _interface._company._configuration.feature.comptability
    },
    {
      icon: 'life-ring',
      key: 'support-section',
      menuKey: 'configurations-settings',
      label: 'supportTitle',
      component: SupportConfiguration,
      keywords: [],
      show: true
    },
    {
      icon: 'toolbox',
      key: 'jobs-section',
      menuKey: 'jobs-settings',
      label: 'jobs',
      component: Jobs,
      keywords: [],
      show: true
    },
    {
      icon: 'key',
      key: 'roles-section',
      menuKey: 'roles-settings',
      label: 'roles',
      component: Permissions,
      keywords: [],
      show: true
    },
    {
      icon: 'file-chart-pie',
      key: 'reports-section',
      menuKey: 'reports-settings',
      label: 'reports',
      component: Reports,
      keywords: [],
      show: true
    },
    {
      menuKey: 'customizable-fields-settings'
    },
    {
      icon: 'book-spells',
      key: 'marketplace-activation-section',
      menuKey: 'marketplace-settings',
      label: 'activateMarketplace',
      component: MarketplaceActivation,
      keywords: [],
      show: true
    },
    {
      icon: 'address-card',
      key: 'marketplace-info-section',
      menuKey: 'marketplace-settings',
      label: 'companyInfo',
      component: MarketplaceDetails,
      keywords: [],
      show: true
    },
    // {
    //   icon: 'toolbox',
    //   key: "marketplace-jobs-section",
    //   menuKey: "marketplace-settings",
    //   label: "jobs",
    //   component: MarketplaceJobs,
    //   keywords: [],
    //   show: true
    // },
    {
      icon: _interface.company?.currency?.icon || 'euro-sign',
      key: 'pricing-section',
      menuKey: 'marketplace-settings',
      label: 'pricing',
      component: MarketplacePricing,
      keywords: [],
      show: true
    },
    // {
    //   icon: 'shield-check',
    //   key: "administrative-documents-section",
    //   menuKey: "marketplace-settings",
    //   label: "administrativeDocs",
    //   component: MarketplaceDocuments,
    //   keywords: [],
    //   show: true
    // }

    // -------------------------------------------------------------------------
    {
      icon: 'key',
      key: 'tokenApi-section',
      menuKey: 'tokenApi-settings',
      label: 'tokenApi',
      component: ApiTokenConfiguration,
      keywords: [],
      show: true
    }
  ].filter((e) => e.show);

const features = (_interface) =>
  [
    {
      key: 'bobdepannage',
      img: (
        <img
          alt="Bob! Dépannage"
          src={bobdepannage}
          style={{ width: 44 }}
        />
      ),
      descriptionKey: 'bobDepannageDescription',
      title: 'bobDepannage',
      show: true
    },
    {
      key: 'marketplace',
      title: 'marketplace',
      icon: 'book-spells',
      descriptionKey: 'marketplaceDescription',
      show: true
    },
    {
      key: 'dashboard',
      title: 'dashboard',
      icon: 'chart-line',
      descriptionKey: 'dashboardDescription',
      show: true
    },
    {
      key: 'preventif',
      title: 'maintenancesTitle',
      icon: 'heartbeat',
      descriptionKey: 'preventifDescription',
      show: true
    },
    {
      key: 'agenda',
      title: 'agenda',
      icon: 'calendar',
      descriptionKey: 'calendarDescription',
      show: true
    },
    {
      key: 'guarantee',
      title: 'guaranteesTitle',
      icon: 'shield-check',
      descriptionKey: 'guaranteesDescription',
      show: _interface.isClient
    },
    {
      key: 'collaborator',
      title: 'techniciansTitle',
      icon: 'user-check',
      descriptionKey: 'techniciansDescription',
      show: true
    },
    {
      key: 'comptability',
      title: 'accountingTitle',
      icon: 'calculator',
      descriptionKey: 'accountingDescription',
      show: _interface.isClient
    },
    {
      key: 'ticket_public',
      title: 'publicInterfaceTitle',
      icon: 'user-secret',
      descriptionKey: 'publicInterfaceDescription',
      show: true
    },
    {
      key: 'inventory',
      title: 'inventoryTitle',
      icon: 'box-taped',
      descriptionKey: 'inventoryDescription',
      show: true
    },
    {
      key: 'contact',
      title: 'usersTitle',
      icon: 'user',
      descriptionKey: 'usersDescription',
      show: true
    },
    {
      key: 'equipment',
      title: 'menuItemEquipments',
      icon: 'wrench-simple',
      descriptionKey: 'equipmentDescription',
      show: true
    },
    {
      key: 'seuil',
      title: 'threshold',
      icon: 'money-check-edit',
      descriptionKey: 'thresholdDescription',
      show: true
    },
    {
      key: 'streetview',
      title: 'realTimeTracking',
      icon: 'street-view',
      descriptionKey: 'realTimeTrackingDescription',
      show: true
    },
    {
      key: 'lease',
      title: 'leasesTitle',
      icon: 'house',
      descriptionKey: 'leasesDescription',
      show: true
    },
    {
      key: 'team',
      title: 'collaborativeCommunication',
      icon: 'message',
      descriptionKey: 'collaborativeCommunicationDescription',
      show: false
    },
    {
      key: 'contract',
      title: 'contracts-and-bpus',
      icon: 'file-contract',
      descriptionKey: 'contractsDescription',
      show: true
    },
    {
      key: 'safety_committee',
      title: 'safetyCommitteeTitle',
      icon: 'tasks',
      descriptionKey: 'safetyCommitteeDescription',
      show: _interface.isClient && _interface.type === 'client'
    },
    {
      key: 'budgets',
      title: 'budgetsTitle',
      icon: 'wallet',
      descriptionKey: 'budgetsDescription',
      show: _interface.isClient && _interface.type === 'client'
    },
    {
      key: 'meters',
      title: 'metersTitle',
      icon: 'meter',
      descriptionKey: 'metersDescription',
      show: true
    }
  ].filter((e) => e.show);

export const getSections = (_interface, menuKey) => {
  if (menuKey !== 'customizable-fields-settings') {
    return sections(_interface).filter((section) => section.menuKey === menuKey);
  } else {
    return getMenus(_interface)
      .filter((menu) => menu.customFields)
      .concat(additionalCustomFielsMenus.filter((m) => _interface._company._configuration.feature[m.feature]))
      .map((m) => formatFieldsSection(m));
  }
};

export const getFeatures = (_interface) =>
  features(_interface).sort((a, b) => (a.title < b.title) * -1 + (a.title > b.title) * 1);

const formatFieldsSection = (menu) => ({
  icon: menu.icon,
  entity: menu.entity,
  label: menu.label,
  menuKey: 'customizable-fields-settings',
  component: CustomField
});
