import Equipment from 'entities/Equipment/Equipment';
import Maintenance from 'entities/Maintenance/Maintenance';
import Ticket from 'entities/Ticket/Ticket';
import Guarantee from 'entities/Guarantee/Guarantee';
import QRCode from '../QRCode';
import EntityTabPicker from 'components/inputs/EntityTabPicker';
import Stock from 'entities/StockPage/Stock';
import { linkStocksToEquipment } from 'store/equipmentsSlice';
import Documents from 'modules/documents';
import BoxWrapperScrollable from 'components/BoxWrapperScrollable';
import Feed from 'modules/comments/Feed';

const EquipmentTabs = (configuration, element, role, auth) =>
  !!element &&
  [
    {
      label: 'subEquipments',
      icon: 'wrench-simple',
      count: element._equipments?.length,
      show: true,
      render: () => (
        <Equipment
          disableGutters
          sublistParent={element}
        />
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'menuItemDocuments',
      icon: 'clouds',
      count: element.tabs?.docs || 0,
      show: true,
      render: (element, isLoading) => (
        <Documents
          endpoint_prefix={`equipment_documents/${element._id}`}
          tab_id={element.documents_tab_id}
        />
      )
    },
    {
      label: 'menuItemComments',
      icon: 'notes',
      count: 0,
      show: Boolean(element.comments_tab_id && configuration.isClient),
      render: (element, isLoading) => (
        <div className="w-full h-full bg-white rounded-md p-4">
          <Feed comments_tab_id={element.comments_tab_id} />
        </div>
      )
    },
    {
      label: 'qrCode',
      icon: 'qrcode',
      count: 0,
      show: Boolean(element) && configuration.equipments_qr_code,
      render: () => (
        <QRCode
          element={element}
          type="equipments"
          line1={element.name}
          line2={element._location?._parentLocations.at(-1)?.name || element._location?.name || ''}
        />
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'guaranteesTitle',
      icon: 'shield-check',
      count: element.tabs?.guarantees,
      show: configuration.feature.guarantee,
      render: () => (
        <Guarantee
          disableGutters
          defaultForm={{ _equipment: element }}
          hiddenFilters={{ equipments: [element._id] }}
        />
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'interventions',
      icon: 'files',
      count: element.tabs?.tickets,
      show: true,
      render: () => (
        <BoxWrapperScrollable>
          <Ticket
            disableCreate={
              !(
                configuration.isClient ||
                (configuration.isContractor && element._contractor === auth.interface._company._id)
              )
            }
            disableGutters
            defaultForm={{
              _equipments: [element],
              _locations: element._location ? [element._location] : [],
              ...(configuration.isContractor ? { _client: element?._client } : {})
            }}
            defaultFilters={{ equipments: [element], section: { closed: true } }}
          />
        </BoxWrapperScrollable>
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'menuItemMaintenances',
      icon: 'heartbeat',
      count: element.tabs?.maintenances,
      show: configuration.feature.preventif,
      render: () => (
        <BoxWrapperScrollable>
          <Maintenance
            disableGutters
            disableCreate={
              !(
                configuration.isClient ||
                (configuration.isContractor && element._contractor === auth.interface._company._id)
              )
            }
            defaultForm={{ _equipments: [element] }}
            defaultFilters={{ equipments: [element] }}
          />
        </BoxWrapperScrollable>
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'menuItemInventory',
      icon: 'box',
      count: element.tabs?.inventory,
      show: configuration.feature.inventory,
      render: (props) => (
        <EntityTabPicker
          Entity={Stock}
          defaultFilters={{}}
          initialValues={{ _products: element?._products || [] }}
          fieldPath="_products"
          elementId={element?._id}
          action={linkStocksToEquipment}
          {...props}
        />
      ),
      class: 'tabNoPadding'
    }
  ].filter((e) => e.show);

export default EquipmentTabs;
