const BLACHERE_PRODUCTION_ID = '67376f08c7b8cd711a64fd4a';
const BLACHERE_BATIMENT_ID = '67376f235e17b37e4a252c3a';
const BLACHERE_DERAT_ID = '67376f2b3d1a5ad1672a68ff';
const BLACHERE_DECHETS_ID = '67376f30fda09febe98c4b5a';
const BLACHERE_CLEAN_ID = '67376f35e4bb30371cc9d5dc';

const BLACHERE_FOUR_ID = '67376f4727f5a0e79755ee4a';
const BLACHERE_FROID_ID = '67376f3c8343b077fe804598';
const BLACHERE_MATERIEL_ID = '67376f424bc18dfca40e5042';
const BLACHERE_PRODUCTION_PREVENTIF_ID = '67376f519d5ad3dfc2079815';
const BLACHERE_MISC_ID = '67376f4cc5ce506a1a5c023a';

// export const blachDomains = [
//   { value: 'production', label: 'Production' },
//   { value: 'batiment', label: 'Batiment' },
//   { value: 'derat', label: 'Dératisation' },
//   { value: 'dechets', label: 'Déchets' },
//   { value: 'clean', label: 'Nettoyage' }
// ];

// export const blachSubDomains = [
//   { value: 'froid', label: 'Froid', _id: '5e29edb69cbeb4004a7014b4' },
//   { value: 'materiel', label: 'Materiel', _id: '5e29edc19cbeb4004a7014b6' },
//   { value: 'four', label: 'Four', _id: '5e2c688659edf5004af58e23' },
//   { value: 'autres', label: 'Autres', _id: '613086d2b856200096edd351' },
//   { value: 'production_preventif', label: 'Maintenance', _id: '613086d2b856200096edd351' }
// ];

export const blachDomains = [
  { value: BLACHERE_PRODUCTION_ID, label: 'Production', key: 'production' },
  { value: BLACHERE_BATIMENT_ID, label: 'Batiment', key: 'batiment' },
  { value: BLACHERE_DERAT_ID, label: 'Dératisation', key: 'derat' },
  { value: BLACHERE_DECHETS_ID, label: 'Déchets', key: 'dechets' },
  { value: BLACHERE_CLEAN_ID, label: 'Nettoyage', key: 'clean' }
];

export const blachSubDomains = [
  { value: BLACHERE_FROID_ID, label: 'Froid', key: 'froid' },
  { value: BLACHERE_MATERIEL_ID, label: 'Materiel', key: 'materiel' },
  { value: BLACHERE_FOUR_ID, label: 'Four', key: 'four' },
  { value: BLACHERE_PRODUCTION_PREVENTIF_ID, label: 'Maintenance', key: 'production_preventif' },
  { value: BLACHERE_MISC_ID, label: 'Autres', key: 'autres' }
];
