import { SPLASH_AVATAR } from 'constants/localStorage';
import { COMPANY_CLIENT } from 'constants/roles';

export const truncateText = (text, max = 50) => (text?.length > max ? `${text.substring(0, max)}...` : text);

export const initCrisp = () => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = 'c89aac0b-700d-4feb-b3f0-c49783147164';
  (function () {
    const d = document;
    const s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
};

export const setInterfaceCrisp = (_interface) => {
  try {
    window.$crisp.push(['set', 'session:data', [[['Role', _interface._role?.name || '']]]]);
    window.$crisp.push([
      'set',
      'session:data',
      [
        [
          [
            'Master',
            `https://bob-desk.com/master/${_interface._company.type === COMPANY_CLIENT ? 'clients' : 'contractors'}/` +
              _interface._company._id
          ]
        ]
      ]
    ]);
    window.$crisp.push(['set', 'user:company', ['[F] ' + _interface._company.name]]);
  } catch (err) {
    console.log('Crisp error', err);
  }
};

export const setSplashAvatar = (_interface) => {
  const avatar = _interface._company.avatar;
  if (avatar) {
    localStorage.setItem(SPLASH_AVATAR, avatar);
    window.$crisp.push(['set', 'user:avatar', [avatar]]);
  } else {
    localStorage.removeItem(SPLASH_AVATAR);
  }
};

export const setCrispUser = (user) => {
  try {
    window.$crisp.push(['set', 'user:nickname', [`${user.firstName} ${user.lastName}`]]);
    window.$crisp.push(['set', 'user:email', [user.email]]);
    window.$crisp.push(['set', 'user:phone', [user.phone || '+33000000000']]);
  } catch (err) {
    console.log('Crisp error', err);
  }
};
