import { GroupedVirtuoso } from 'react-virtuoso';
import { format, isToday, isYesterday, isThisMonth, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useUI } from 'hooks/useUI';
import TicketPreviewNew from './TicketPreviewNew';

const capitalizeFirstLetter = (string) => {
  if (!string) return string; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function GroupedVirtuosoNew({ groupRef, data, endReached, Footer, hasMore }) {
  const { t } = useTranslation();
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  // Function to organize tickets by creation date into categories by returning a object
  const GroupItemsByDate = (arrTicket /*,db*/) => {
    const grouped = {
      today: [],
      yesterday: [],
      dayNames: {},
      datesOfMonth: {},
      monthsOfYear: {}
    };

    arrTicket.forEach((ticket) => {
      const createdAt = new Date(ticket.data.created_at);

      if (isToday(createdAt)) {
        grouped.today.push(ticket);
      } else if (isYesterday(createdAt)) {
        grouped.yesterday.push(ticket);
      } else if (createdAt >= subDays(new Date(), 7)) {
        const dayName = capitalizeFirstLetter(format(createdAt, 'EEEE', { locale }));
        if (!grouped.dayNames[dayName]) {
          grouped.dayNames[dayName] = [];
        }
        grouped.dayNames[dayName].push(ticket);
      } else if (isThisMonth(createdAt)) {
        const dayOfMonth = capitalizeFirstLetter(format(createdAt, 'd/MM/yyyy', { locale }));
        if (!grouped.datesOfMonth[dayOfMonth]) {
          grouped.datesOfMonth[dayOfMonth] = [];
        }
        grouped.datesOfMonth[dayOfMonth].push(ticket);
      } else {
        const monthOfYear = capitalizeFirstLetter(format(createdAt, 'MMMM yyyy', { locale }));
        if (!grouped.monthsOfYear[monthOfYear]) {
          grouped.monthsOfYear[monthOfYear] = [];
        }
        grouped.monthsOfYear[monthOfYear].push(ticket);
      }
    });

    return grouped;
  };

  // Get the name of the group to display and translate them for example : today , june , 2022 ect..
  const getGroupNames = (grouped) => {
    const groupNames = [];
    if (grouped.today.length > 0) groupNames.push(t('Today'));
    if (grouped.yesterday.length > 0) groupNames.push(t('yesterday'));

    for (const dayName in grouped.dayNames) {
      if (grouped.dayNames[dayName].length > 0) {
        groupNames.push(dayName);
      }
    }

    for (const date in grouped.datesOfMonth) {
      if (grouped.datesOfMonth[date].length > 0) {
        groupNames.push(date);
      }
    }

    for (const month in grouped.monthsOfYear) {
      if (grouped.monthsOfYear[month].length > 0) {
        groupNames.push(month);
      }
    }
    return groupNames;
  };

  const groupedElements = GroupItemsByDate(data /*,db*/);

  const groupNames = getGroupNames(groupedElements);

  // Create a array with the counts of tickets of each categorie and filter out the empty categorie
  const groupCounts = [
    groupedElements.today.length,
    groupedElements.yesterday.length,
    ...Object.values(groupedElements.dayNames).map((group) => group.length),
    ...Object.values(groupedElements.datesOfMonth).map((group) => group.length),
    ...Object.values(groupedElements.monthsOfYear).map((group) => group.length)
  ].filter((count) => count > 0); // Filter out empty counts

  // Get the flattened list of tickets ordered from most recent (today) to less recent (monthly groups & yearly groups)
  const flatElements = [
    ...groupedElements.today,
    ...groupedElements.yesterday,
    ...Object.values(groupedElements.dayNames).flat(),
    ...Object.values(groupedElements.datesOfMonth).flat(),
    ...Object.values(groupedElements.monthsOfYear).flat()
  ]; // Ensure that flatElements matches the order of groupCounts

  return (
    <GroupedVirtuoso
      data={data}
      ref={groupRef}
      groupCounts={groupCounts} // Number of items in each group
      groupContent={(index) => (
        <div className="bg-[#f5f5f5] py-1 pl-2 font-semibold">
          {groupNames[index]} {/* Render group header (e.g., Today, Yesterday) */}
        </div>
      )}
      itemContent={(index) => {
        const ticket = flatElements[index];
        return <TicketPreviewNew ticket={ticket} />;
      }}
      style={{ overflowX: 'hidden' }}
      endReached={endReached}
      components={{ Footer: () => <Footer hasMore={hasMore} /> }}
    />
  );
}
