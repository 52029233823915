import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect, useState } from 'react';
import { getElements as getInterveners } from 'store/intervenersSlice';
import { exportTickets, getBookmarks, newTicketList, syncTicketList } from 'store/ticketsSlice.js';
import { formatFilter, LIMIT } from './baseFilters.js';
import { getColor, normalizeFilters, prepareFiltersForApi } from './utils.js';
import MenuItem from 'components/menu/MenuItem.js';
import Menu from 'components/menu/Menu.js';
import { Button, InputBase } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon.js';
import { useConfiguration } from 'hooks/useConfiguration.js';
import { useTranslation } from 'react-i18next';
import { CloudDownload } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import useNotifications from 'hooks/useNotifications.js';
import TicketExportsNew from 'entities/Ticket/TicketExportsNew.js';

export const syncTickets = ({ dispatch }) => {
  dispatch(syncTicketList);
};

export const fetchTickets = ({ auth, setData, dispatch, setSkip, skip, limit, setHasMore }) => {
  return async (filters, isSkipped = false) => {
    
    const nextSkip = isSkipped ? skip + limit : 0;
    setSkip(nextSkip); // State update outside async flow (to avoid infinite loop)
    
    const formattedFilters = formatFilter(filters, auth, nextSkip, limit);

    try {
      dispatch(
        newTicketList,
        { filters: formattedFilters },
        {
          onSuccess: (data) => {
            const newTickets = data?.tickets || [];
            const allTicketsLoaded = newTickets.length < limit;

            setHasMore(!allTicketsLoaded); // Indique si d'autres tickets sont disponibles

            setData((prevData) => ({
              tickets: isSkipped ? [...prevData.tickets, ...data?.tickets] : data?.tickets,
              count: data.count
            }));
          },
          onError: () => {
            setData([]);
            setHasMore(false);
          }
        }
      );
    } catch {
      setData([]);
      setHasMore(false);
    }
  };
};

export const useFilteredBookmarks = (calendar, appliedFilters, setBookmarkState, bookmarkState) => {
  const { dispatch } = useAsyncDispatch();

  useEffect(() => {
    const fetchBookmarks = async () => {
      setBookmarkState({ ...bookmarkState, isLoading: true });
      const initialBookmarks = bookmarkState.bookmarks;

      if (!calendar) {
        dispatch(
          getBookmarks,
          { filters: appliedFilters },
          {
            onSuccess: (data) => {
              const filteredKeys = Object.keys(data.bookmarks).filter((key) => data.bookmarks[key]);
              setBookmarkState({
                bookmarks: initialBookmarks.filter((bm) => filteredKeys.includes(bm.key)),
                isLoading: false
              });
            }
          }
        );
      } else {
        dispatch(getInterveners, {
          limit: { startIndex: 0, stopIndex: LIMIT },
          filters: {
            tab: { collaborator: true, mine: false, public: false },
            deleted: false,
            sort: { sort: 1, value: 'companyName' }
          }
        }).then(({ data }) => {
          setBookmarkState({
            bookmarks: [
              initialBookmarks[0],
              ...data.elements?.slice(0, 9).map(({ _id, color, firstName, lastName }) => ({
                label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
                key: _id,
                color
              }))
            ],
            isLoading: false
          });
        });
      }
    };

    fetchBookmarks();
  }, []);
};

const getDefaultSet = (appliedFilters) => ({
  title: 'defaultFilterSet',
  filters: normalizeFilters({ filters: appliedFilters }),
  _id: '123'
});

export const createFilterSetsManager = (appliedFilters) => {
  const last_used_filter_set = localStorage.getItem('last_used_filter_set');
  if (last_used_filter_set && !appliedFilters.deleted) return JSON.parse(last_used_filter_set);
  else
    return {
      filterSets: [getDefaultSet(appliedFilters)],
      selectedSet: getDefaultSet(appliedFilters),
      orderFilters: Object.keys(appliedFilters)
    };
};

export const generateFilters = (orderFilters, showableFilters) =>
  orderFilters
    .map((path) => showableFilters.find((f) => f.props.path === path))
    .filter(Boolean)
    .map(({ Component, props }) => (
      <Component
        key={props.label}
        {...props}
      />
    ));

export const ticketMenuItems = ({ t, fetchTickets, appliedFilters, setIsTrashOpen, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="left-start"
    />,
    <MenuItem
      icon="trash"
      label="trash"
      onClick={() => setIsTrashOpen(true)}
    />
  ];
};

const PopoverExportTickets = ({ appliedFilters }) => {
  const config = useConfiguration();
  const ticketExport = TicketExportsNew(config);
  const [selectedFields, setSelectedFields] = useState(ticketExport.map(({ key }) => key));
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();
  const { activeIconColor, inactiveIconColor } = getColor('sky');

  // const toggleSelectAll = () => {
  //   setSelectedFields(selectedFields.length === ticketExport.length ? [] : ticketExport.map(({ key }) => key));
  // };

  const handleSelectField = ({ key, checked }) => {
    setSelectedFields((prev) => (checked ? [...prev, key] : prev.filter((field) => field !== key)));
  };

  const handleExport = () => {
    setIsLoading(true);

    let filters = prepareFiltersForApi({ filters: appliedFilters });
    filters = filters.filter((f) => f.key !== 'limit' && f.key !== 'skip');

    try {
      dispatch(exportTickets, { filters, fields: selectedFields }).then((res) => {
        const filename = 'tickets.csv';

        const csvExport = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64;charset=UTF-8'
        });
        saveAs(csvExport, filename);
        notify.success(t('exportSuccessMessage'));
        setIsLoading(false);
      });
    } catch {
      notify.error();
      setIsLoading(false);

    }
  };

  const filteredTickets = ticketExport.filter(({ label }) => t(label).toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="flex flex-col gap-1 p-1">
      {/* <div className="flex gap-1 items-center"> */}
        <InputBase
          className="!rounded-lg px-4 py-2 border"
          placeholder={t('findValue')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* <Button
          className="!text-xs"
          onClick={toggleSelectAll}
          disabled={search.length}
          >
          {selectedFields.length === ticketExport.length ? t('unselectAll') : t('selectAll')}
          </Button> 
      </div> */}

      <div className="max-h-[50vh] w-80 overflow-auto">
        {filteredTickets.map(({ label, key }) => {
          const isChecked = selectedFields.includes(key);
          return (
            <div
              key={key}
              className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 rounded-lg cursor-pointer"
              onClick={() => handleSelectField({ key, checked: !isChecked })}
            >
              <FAIcon
                collection={isChecked ? 'fas' : 'fal'}
                icon={isChecked ? 'circle-check' : 'circle'}
                size="medium"
                className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
              />
              <span className="text-sm">{t(label)}</span>
            </div>
          );
        })}
      </div>

      <Button
        variant="outlined"
        onClick={handleExport}
        endIcon={
          isLoading ? (
            <FAIcon
              icon="spinner"
              className="fa-spin"
              collection="fas"
              size="medium"
            />
          ) : (
            <CloudDownload />
          )
        }
        disabled={isLoading || !selectedFields.length}
        className="!rounded-lg"
      >
        {t('export')}
      </Button>
    </div>
  );
};

export const trashMenuItems = ({ t, fetchTickets, appliedFilters, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="left-start"
    />
  ];
};
