import DragScrollContainer from 'components/ui/DragScrollContainer';

export default function FilterBar({ filters = [], sortingOrders = [] }) {
  if (!filters.length) return null;

  return (
    <DragScrollContainer
      className="relative flex items-center"
      scrollClassName="pt-2"
      sortingOrders={sortingOrders}
    >
      {filters.map((filter, filterIdx) => (
        <div key={filterIdx}>{filter}</div>
      ))}
    </DragScrollContainer>
  );
}
