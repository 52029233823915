import FilterBar from 'components/filters/FilterBar';
import SearchBar from 'components/filters/SearchBar';
import { Form } from 'frmx';
import { useEffect, useState } from 'react';
import BookmarkBar from './BookmarkBar';

export default function FilterAndSearchBarForm({
  search = true,
  filterSetsManager,
  setFilterSetsManager,
  totalResults,
  menuItems = [],
  searchPath = 'search',
  initialValues,
  setInitialValues,
  onChange,
  onSubmit,
  filters = [],
  sortingOrders = [],
  withFilters,
  downloadLoading,
  className = '',
  bookmarkState,
  resizeable = false,
  appliedFilters,
  dataFilters,
  setResize = () => {}
}) {
  // Ajoutez un état local pour forcer la reconstruction du Form apres le changement de
  const [key, setKey] = useState(0);

  // Surveillez les changements d'initialValues
  useEffect(() => {
    setKey((prev) => prev + 1);
    onChange(initialValues);
  }, [initialValues]);

  return (
    <Form
      key={key}
      className={className}
      initialValues={initialValues}
      {...(onChange ? { afterChange: onChange } : {})}
      {...(onSubmit ? { onSubmit: onSubmit } : {})}
    >
      {search && (
        <SearchBar
          totalResults={totalResults}
          menuItems={menuItems}
          path={searchPath}
          filterSetsManager={filterSetsManager}
          setFilterSetsManager={setFilterSetsManager}
          downloadLoading={downloadLoading}
          resizeable={resizeable}
          setResize={setResize}
          appliedFilters={appliedFilters}
          setInitialValues={setInitialValues}
          dataFilters={dataFilters}
        />
      )}
      {withFilters && (
        <div className="mx-2">
          <FilterBar
            filters={filters}
            sortingOrders={sortingOrders}
          />
        </div>
      )}
      <div className="mx-2">
        <BookmarkBar bookmarkState={bookmarkState} />
      </div>
    </Form>
  );
}
