import useKeys from '@flowsn4ke/usekeys';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import EntityX from 'components/inputs/EntityX';
import PhoneField from 'components/inputs/PhoneField';
import Client from 'entities/Client/Client';
import Equipment from 'entities/Equipment/Equipment';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import Manager from 'entities/Manager/Manager';
import User from 'entities/User/User';
import { Field, Form, useField, useFieldObserver, useForm, useSubmitListener } from 'frmx';
import { useAuth } from 'hooks/useAuth';
import { useConfiguration } from 'hooks/useConfiguration';
import { useRole } from 'hooks/useRole';
import { KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID } from 'index';
import { blachDomains, blachSubDomains } from 'lists/blachere';
import { isArray } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const BLACHERE_ID = '5d91c909038b500034223bfc';
const BOULANGERIES_LOUISE_ID = '6400a8498cb7770013b76530';

export default function TicketForm({ isCreate }) {
  const [equipmentCategory, setEquipmentCategory] = useState(null);
  const [equipmentSubCategory, setEquipmentSubCategory] = useState(null);
  const { setOneField, setOneError } = useForm();
  const { search, pathname } = useLocation();

  const auth = useAuth();
  const role = useRole();
  const config = useConfiguration();
  const seuil_enabled = config.feature.seuil;
  const equipment_enabled = config.feature.equipment;
  const isClient = auth.interface.isClient;
  const isBlachere = auth.interface._company._id === BLACHERE_ID;
  const isBoulangeriesLouise = auth.interface._company._id === BOULANGERIES_LOUISE_ID;
  const isManager = config.isManager;
  const client = useFieldObserver('_client');
  const _locations = useFieldObserver('_locations');
  const hasPublicCreatorEmailField = config?.ticket_public?.creator_email_enabled;
  const hasPublicCreatorPhoneField = config?.ticket_public?.creator_phone_enabled;
  const hasPublicCreatorNameField = config?.ticket_public?.creator_enabled;
  const hasPublicEquipmentField = config?.ticket_public?.equipment;
  const hasPublicTitleField = !config?.ticket_public?.hide_ticket;
  const isPublic = auth.interface.isPublic;
  const isLocalPublicInterface = pathname.includes('local');
  const searchParams = new URLSearchParams(search);
  const equipmentId = searchParams.get('equipmentId');
  const equipmentName = searchParams.get('equipmentName');

  useEffect(() => {
    if (isPublic && equipmentId) {
      setOneField('_equipments', [{ _id: equipmentId, name: equipmentName }]);
    }
  }, [search]);

  const { t } = useTranslation();

  const _equipments = useFieldObserver('_equipments');
  const _jobs = useFieldObserver('_jobs');

  const has_equipments_with_location_selected = (Array.isArray(_equipments) ? _equipments : [])?.some(
    (eq) => eq?._location !== null
  );

  useEffect(() => {
    const equipments = isArray(_equipments) ? _equipments : [_equipments].filter((e) => !!e);

    if (!isLocalPublicInterface && !_jobs.length && !!equipments.length) {
      setOneField(
        '_jobs',
        equipments.filter((e) => !!e).reduce((a, b) => [...a, ...(b?._jobs || [])], [])
      );
    }

    if (!isLocalPublicInterface && has_equipments_with_location_selected && isCreate) {
      setOneField(
        '_locations',
        deduplicateLocations(equipments).filter((l) => Boolean(l))
      );
    }
  }, [_equipments]);

  const [submittedOnce, setSubmittedOnce] = useState(false);
  useSubmitListener(() => !submittedOnce && setSubmittedOnce(true));

  const publicLocationError = useMemo(() => {
    if (isPublic && !isLocalPublicInterface) {
      const isError = config?.ticket_location_mandatory && !(isArray(_locations) ? _locations.length : _locations);
      setOneError('_locations', isError);
      return isError;
    }
  }, [submittedOnce, _locations, isPublic, isLocalPublicInterface]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <UrgencyField />
      </Grid>

      {!!config?.job_domains?.length && !isBlachere && !isBoulangeriesLouise && (
        <Grid
          item
          xs={12}
        >
          <JobDomainsField setEquipmentCategory={setEquipmentCategory} />
        </Grid>
      )}

      {!isClient && !isPublic && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_client"
            entityName={'clients'}
            entity={Client}
            disabled={!isCreate}
            entityProps={{
              hiddenFilters: {
                authorisations: ['ticket']
              }
            }}
            placeholder={t('client')}
            pickerUniq
          />
        </Grid>
      )}

      {!auth.interface._company._configuration.monolocation && !(auth.interface._locations?.length === 1) && (
        <Grid
          item
          xs={12}
        >
          {isPublic ? (
            <PublicLocationField
              topLocation={_locations}
              client={client}
              isClient={isClient}
              setTopLocation={(loc) => setOneField('_locations', loc)}
              topError={publicLocationError}
            />
          ) : (
            <EntityX
              disabled={(!client && !isClient && !isPublic) || (has_equipments_with_location_selected && isCreate)}
              disabledHelperText={
                has_equipments_with_location_selected && isCreate ? '' : t('pleaseSelectAClientFirst')
              }
              path="_locations"
              entityName="locations"
              entity={Location}
              entityProps={{
                lockClients: true,
                defaultFilters: { ...(client ? { clients: [client] } : {}) },
                defaultForm: !!client && config?.isContractor ? { _client: client } : {}
              }}
              pickerUniq={config?.ticket_location_unique}
              placeholder={t(config?.ticket_location_unique ? 'location' : 'locations')}
              required={config?.ticket_location_mandatory}
              refreshValue
            />
          )}
        </Grid>
      )}

      {(hasPublicTitleField || !isPublic) && (
        <Grid
          item
          xs={12}
        >
          <Field
            path="title"
            isErrorProp="error"
          >
            <TextField
              autoFocus
              variant="outlined"
              label={t('title')}
              fullWidth
            />
          </Field>
        </Grid>
      )}

      {isPublic && hasPublicCreatorNameField && (
        <Grid
          item
          xs={12}
        >
          <Field
            path="creator"
            isErrorProp="error"
          >
            <TextField
              variant="outlined"
              label={t('creator')}
              fullWidth
              type="text"
              autoComplete="name"
            />
          </Field>
        </Grid>
      )}

      {isPublic && hasPublicCreatorEmailField && (
        <Grid
          item
          xs={12}
        >
          <Field
            path="creator_email"
            isErrorProp="error"
          >
            <TextField
              variant="outlined"
              label={t('ticketCreatorEmail')}
              fullWidth
              type="email"
              autoComplete="email"
            />
          </Field>
        </Grid>
      )}

      {isPublic && hasPublicCreatorPhoneField && (
        <Grid
          item
          xs={12}
        >
          <PhoneField
            path="creator_phone"
            label={t('phoneNumber')}
            variant="outlined"
            placeholder={t('phoneNumber')}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <Field
          path="description"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            label={t('description')}
            multiline
            rows={4}
            fullWidth
          />
        </Field>
      </Grid>

      {isBlachere && (
        <Grid
          item
          xs={12}
        >
          {<BlachereField setEquipmentCategory={setEquipmentCategory} />}
        </Grid>
      )}
      {isBoulangeriesLouise && (
        <Grid
          item
          xs={12}
        >
          {<BoulangeriesLouiseField setEquipmentSubCategory={setEquipmentSubCategory} />}
        </Grid>
      )}

      {((!isPublic &&
        ((equipment_enabled && !isBlachere) || (equipment_enabled && equipmentCategory?.length && isBlachere))) ||
        hasPublicEquipmentField) && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            disabled={(!client && !isClient) || Boolean(isPublic && equipmentId)}
            disabledHelperText={Boolean(isPublic && equipmentId) ? null : t('pleaseSelectAClientFirst')}
            path="_equipments"
            refreshValue
            entityName="equipments"
            entity={Equipment}
            entityProps={{
              lockClients: true,
              defaultForm: !!client && config?.isContractor ? { _client: client } : {},
              defaultFilters: {
                ...(client ? { clients: [client] } : {}),
                ...(equipmentCategory ? { categories: equipmentCategory } : {}),
                ...(equipmentSubCategory ? { subcategories: equipmentSubCategory } : {}),
                locations: isArray(_locations) ? _locations : [_locations]
              },
              disableFilter: isPublic || isBlachere || isBoulangeriesLouise
            }}
            placeholder={t('equipmentsTitle')}
            pickerUniq={config.isBlachere}
          />
        </Grid>
      )}

      {!config?.hide_job && (!config?.job_domains?.length || isManager) && role.permission('tickets', 'job') && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_jobs"
            entity={Job}
            placeholder={t('job')}
            required={config?.job_mandatory}
            refreshValue
          />
        </Grid>
      )}

      {isManager && role.permission('tickets', 'managers') && (
        <>
          <Grid
            item
            xs={12}
          >
            <EntityX
              path="_managers"
              entityName="managers"
              entity={Manager}
              placeholder={t('managers')}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <EntityX
              path="_contacts"
              entityName="users"
              entity={User}
              placeholder={t('usersTitle')}
            />
          </Grid>
        </>
      )}

      {seuil_enabled && !isPublic && auth.interface.type !== 'user' && <SeuilSlider />}
    </>
  );
}

function SeuilSlider() {
  const val = useFieldObserver('seuil');
  const enabled = useFieldObserver('seuil_enable');

  const { value, setValue } = useField('seuil_enable');

  useEffect(() => {
    setValue(enabled);
  }, [enabled]);

  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => setValue(e.target.checked)}
            />
          }
          label={t('maximumForInterventionWithoutValidation')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Box style={{ width: '100%', padding: 4 }}>
          {enabled && (
            <>
              <Typography gutterBottom>
                {t('thresholdWithoutValidation')}&nbsp;
                {val > 0 ? <strong>{val}€</strong> : 'Devis obligatoire'}
              </Typography>
              <Field
                disabled={!enabled}
                path="seuil"
                type="range"
                getValueFromArgs={(args) => args[1]}
              >
                <Slider
                  min={0}
                  max={2000}
                  step={10}
                  valueLabelDisplay="auto"
                />
              </Field>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
}

function JobDomainsField({ setEquipmentCategory }) {
  const config = useConfiguration();
  const domains = config?.job_domains;
  const { setOneField } = useForm();

  const { value: category, setValue: setCategory, error: categoryError } = useField('category');

  const theme = useTheme();
  const k = useKeys();
  const { t } = useTranslation();

  const handleDomainChange = useCallback((domain_id) => {
    setCategory(domain_id);
    const next = domains.find((d) => d._id === domain_id);
    setOneField('_jobs', next?._job ? [next._job] : []);

    if (KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID === domain_id) {
      setOneField('seuil_enable', true);
      setOneField('seuil', 0);
    }

    next?._categories && setEquipmentCategory(next._categories.map((c) => ({ _id: c._id })));
  }, []);

  return domains?.length > 5 ? (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <InputLabel
        htmlFor="outlined-age-native-simple"
        className="bg-white"
      >
        {t('domainLabel')}
      </InputLabel>
      <Select
        fullWidth
        value={category}
        onChange={(e) => handleDomainChange(e.target.value)}
      >
        {domains.map((domain) => {
          return (
            <MenuItem
              key={domain._id}
              value={domain._id}
            >
              {domain.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : domains?.length > 0 ? (
    <>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        exclusive
        value={category}
        onChange={(e, nextCategoryId) => handleDomainChange(nextCategoryId)}
      >
        {domains.map((d, i) => (
          <ToggleButton
            key={k(i)}
            size="small"
            style={{
              width: '100%',
              fontSize: 11,
              backgroundColor: d._id === category ? theme.palette.primary.main : '',
              color: d._id === category ? 'white' : theme.palette.primary.main,
              ...(domains?.length > 5
                ? {
                    borderRadius: '16px',
                    marginBottom: 2,
                    borderColor: '#e0e0e0'
                  }
                : {})
            }}
            value={d._id}
          >
            {d.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {categoryError && (
        <Typography
          variant="body2"
          color="error"
          style={{ marginTop: '1em' }}
        >
          {t('pleaseSelectDomainToCreateTicket')}
        </Typography>
      )}
    </>
  ) : null;
}

function UrgencyField() {
  const { value: urgency, setValue: setUrgency, error, onBlur } = useField('urgency');
  const config = useConfiguration();
  const urgencies = config.urgencies;
  const { t } = useTranslation();

  const delayTypes = [
    { value: 'days', label: t('days') },
    { value: 'hours', label: t('hours') },
    { value: 'custom', label: t('custom') },
    { value: 'none', label: t('withoutTimeLimit') }
  ];

  const k = useKeys();

  return (
    <>
      <ToggleButtonGroup
        style={{ width: '100%', marginTop: -7 }}
        exclusive
        value={+urgency}
        onChange={(e, next) => [setUrgency(next), onBlur()]}
      >
        {Object.keys(urgencies).map((key, i) =>
          !!urgencies[key].label?.length ? (
            <ToggleButton
              key={k(i)}
              size="small"
              style={{
                width: '100%',
                fontSize: 11,
                backgroundColor: +urgency === +key ? urgencies[key].color : '',
                color: +urgency === +key ? 'white' : urgencies[key].color
              }}
              value={+key}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {t(urgencies[key].label)}
                {['hours', 'days'].includes(urgencies[key].delayType) && (
                  <span
                    style={{
                      marginLeft: 6,
                      fontSize: 10,
                      textTransform: 'lowercase'
                    }}
                  >
                    {urgencies[key].delay + '' + delayTypes.find((v) => v.value === urgencies[key].delayType).label[0]}
                  </span>
                )}
              </Box>
            </ToggleButton>
          ) : null
        )}
      </ToggleButtonGroup>
      {error && (
        <Typography
          style={{ paddingTop: '1em' }}
          color="error"
        >
          {t('mandatoryUrgenciesErr')}
        </Typography>
      )}
    </>
  );
}

function PublicLocationField({ client, isClient, setTopLocation, subs, topLocation, topError }) {
  const config = useConfiguration();
  const { t } = useTranslation();
  const parent = useFieldObserver('_locations');
  const hasParent = useRef(isArray(parent) ? parent?.length : parent).current;

  return (
    <Form
      initialValues={{ _locations: null }}
      style={{ margin: hasParent ? '24px 0' : 0 }}
    >
      <EntityX
        error={topError}
        afterChange={(next) => setTopLocation(!next && hasParent ? parent : next)}
        disabledHelperText={t('pleaseSelectAClientFirst')}
        path="_locations"
        entityName="locations"
        entity={Location}
        entityProps={{
          disableFilter: true,
          lockClients: true,
          defaultFilters: { ...(client ? { clients: [client] } : {}) },
          defaultForm: !!client && config?.isContractor ? { _client: client } : {},
          hiddenFilters: { subs }
        }}
        placeholder={t(hasParent ? 'sublocation' : 'location')}
        required={config?.ticket_location_mandatory}
        pickerUniq
      />
      <PublicLocationChildren
        client={client}
        isClient={isClient}
        setTopLocation={setTopLocation}
        topLocation={topLocation}
      />
    </Form>
  );
}

function PublicLocationChildren({ client, isClient, setTopLocation, topLocation }) {
  const loc = useFieldObserver('_locations');
  const location = isArray(loc) ? loc[0] : loc;
  const hasChildren = !!location?._locations?.length;
  const subs = location?._id || [];

  return hasChildren ? (
    <PublicLocationField
      client={client}
      isClient={isClient}
      setTopLocation={setTopLocation}
      subs={subs}
      topLocation={topLocation}
    />
  ) : null;
}

function deduplicateLocations(equipments) {
  const locs = [];

  for (let i = 0; i < equipments.length; i++) {
    const loc = equipments[i]._location;

    if (!locs.find((l) => l?._id === loc?._id)) {
      locs.push(loc);
    }
  }
  return locs;
}

function BoulangeriesLouiseField({ setEquipmentSubCategory }) {
  const { setOneField, setOneError } = useForm();
  const { value: category, setValue: setCategory, error: categoryError } = useField('category');
  const { value: domaine, setValue: setDomaine, error: domaineError } = useField('domaine');

  const [localError, setLocalError] = useState(false);

  const { t } = useTranslation();

  const theme = useTheme();
  const k1 = useKeys();
  const k2 = useKeys();

  const handler = useRef((e, cat) => {
    switch (cat) {
      case 'production': {
        setCategory(cat);
        setEquipmentSubCategory([]);
        setOneField('_equipments', []);
        setOneField('_managers', [
          {
            _id: '6400a8498cb7770013b7653d',
            firstName: 'Emilie',
            lastName: 'BUCHOLZ'
          }
        ]);
        setOneError('domaine', true);
        setLocalError(true);
        break;
      }
      case 'batiment': {
        setCategory(cat);
        setEquipmentSubCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneField('_managers', [
          {
            _id: '6400a8498cb7770013b7653d',
            firstName: 'Emilie',
            lastName: 'BUCHOLZ'
          }
        ]);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'cellule': {
        setCategory(cat);
        setEquipmentSubCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneField('_managers', [
          {
            _id: '6400a8498cb7770013b7653d',
            firstName: 'Emilie',
            lastName: 'BUCHOLZ'
          }
        ]);
        setOneError('domaine', true);
        setLocalError(true);
        break;
      }
      case 'nettoyage': {
        setCategory(cat);
        setEquipmentSubCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneField('_managers', [
          {
            _id: '6400a8498cb7770013b7653d',
            firstName: 'Emilie',
            lastName: 'BUCHOLZ'
          }
        ]);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'deratisation': {
        setCategory(cat);
        setEquipmentSubCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneField('_managers', [
          {
            _id: '64217479c9876000119f75d6',
            firstName: 'Ludivine',
            lastName: 'Weber'
          }
        ]);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'chaud': {
        setEquipmentSubCategory([{ _id: '64132b7408148900112a88fd' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'froid': {
        setEquipmentSubCategory([{ _id: '64132b83042d0900124e47dd' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'materiel': {
        setEquipmentSubCategory([{ _id: '64132b92bcf0a7001897d27f' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'cvc': {
        setEquipmentSubCategory([{ _id: '64132b21bcf0a7001897cea6' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'plomberie': {
        setEquipmentSubCategory([{ _id: '64132b3193323f00188fd994' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'vente': {
        setEquipmentSubCategory([{ _id: '64132b478128290011478220' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'elec': {
        setEquipmentSubCategory([{ _id: '64132b61042d0900124e46fd' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      default:
        break;
    }
  });

  return (
    <>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        exclusive
        value={category}
        onChange={handler.current}
      >
        {[
          { value: 'production', label: 'Production' },
          { value: 'cellule', label: 'Cellule' },
          { value: 'batiment', label: 'Batiment' },
          { value: 'nettoyage', label: 'Nettoyage' },
          { value: 'deratisation', label: 'Dératisation' }
        ].map((cat, i) => (
          <ToggleButton
            key={k1(i)}
            size="small"
            style={{
              width: '100%',
              fontSize: 11,
              color: category === cat.value ? 'white' : theme.palette.secondary.main,
              backgroundColor: category === cat.value ? theme.palette.secondary.main : ''
            }}
            value={cat.value}
          >
            {cat.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {category === 'production' && (
        <ToggleButtonGroup
          style={{ width: '100%', marginTop: '1em' }}
          value={domaine}
          onChange={handler.current}
          exclusive
        >
          {[
            { value: 'froid', label: 'Froid', _id: '64132b83042d0900124e47dd' },
            { value: 'chaud', label: 'Chaud', _id: '64132b7408148900112a88fd' },
            {
              value: 'materiel',
              label: 'Matériel',
              _id: '64132b92bcf0a7001897d27f'
            }
          ].map((dom, i) => (
            <ToggleButton
              key={k2(i)}
              size="small"
              style={{
                width: '100%',
                fontSize: 11,
                color: domaine === dom.value ? 'white' : theme.palette.secondary.main,
                backgroundColor: domaine === dom.value ? theme.palette.secondary.main : ''
              }}
              value={dom.value}
            >
              {dom.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}

      {category === 'cellule' && (
        <ToggleButtonGroup
          style={{ width: '100%', marginTop: '1em' }}
          value={domaine}
          onChange={handler.current}
          exclusive
        >
          {[
            { value: 'cvc', label: 'CVC', _id: '64132b21bcf0a7001897cea6' },
            {
              value: 'plomberie',
              label: 'Plomberie',
              _id: '64132b3193323f00188fd994'
            },
            { value: 'vente', label: 'Vente', _id: '64132b478128290011478220' },
            {
              value: 'elec',
              label: 'Electricité',
              _id: '64132b61042d0900124e46fd'
            }
          ].map((dom, i) => (
            <ToggleButton
              key={k2(i)}
              size="small"
              style={{
                width: '100%',
                fontSize: 11,
                color: domaine === dom.value ? 'white' : theme.palette.secondary.main,
                backgroundColor: domaine === dom.value ? theme.palette.secondary.main : ''
              }}
              value={dom.value}
            >
              {dom.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}

      {(categoryError || domaineError || localError) && (
        <FormHelperText
          style={{
            color: 'red',
            margin: '1em 0',
            marginLeft: '.5em'
          }}
        >
          {t('chooseCatAndDomain')}
        </FormHelperText>
      )}
    </>
  );
}

//! DO NOT FUCKING TOUCH @BLACHERE
function BlachereField({ setEquipmentCategory }) {
  const { getOneField, setOneField, setOneError } = useForm();
  const { value: category, setValue: setCategory, error: categoryError } = useField('category');
  const { value: domaine, setValue: setDomaine, error: domaineError } = useField('domaine');

  const [localError, setLocalError] = useState(false);

  const initial_managers = getOneField('_managers');

  const { t } = useTranslation();

  const auth = useAuth();

  const theme = useTheme();
  const k1 = useKeys();
  const k2 = useKeys();

  const handler = useRef((e, cat) => {
    switch (cat) {
      case 'production': {
        setCategory(cat);
        setOneField('_jobs', []);
        setOneError('domaine', true);
        setOneField('_managers', initial_managers);
        setLocalError(true);
        break;
      }
      case 'batiment': {
        setCategory(cat);
        setOneField('_jobs', []);
        setOneField('_equipments', []);
        setEquipmentCategory([]);
        setOneField('_managers', initial_managers);
        setDomaine(null);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'derat': {
        setCategory(cat);
        setOneField('_jobs', [{ _id: '5d9b1839324a640035e654d7', name: 'Dératisation / Nuisibles' }]);
        setOneField('_managers', [
          {
            _id: '63c9259349e8980018eb4421',
            firstName: 'GESTION',
            lastName: 'Dératisation'
          },
          {
            _id: '664df9218ddd32001262114d',
            firstName: 'Céline',
            lastName: 'Parmentier'
          },
          {
            _id: '664df9613af9e6001979c33f',
            firstName: 'Jade',
            lastName: 'Parfait'
          },
          {
            _id: '619213d95d79db00280169d4',
            firstName: 'Melanie',
            lastName: 'FOURMEAUX'
          }
        ]);
        setEquipmentCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'dechets': {
        setCategory(cat);
        setOneField('_jobs', [{ _id: '5e32e2ef2bc788004db45c45', name: 'Déchets' }]);
        setOneField('_managers', [
          {
            _id: '612e03a69443930012b0c09c',
            firstName: 'GESTION',
            lastName: 'Déchets'
          }
        ]);
        setEquipmentCategory([]);
        setOneField('_equipments', []);
        setDomaine(null);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'clean': {
        setCategory(cat);
        setOneField('_jobs', [
          {
            _id: '5d9b1029324a640035e654bd',
            name: 'Ménage / Nettoyage / Propreté'
          }
        ]);
        setEquipmentCategory([]);
        setOneField('_equipments', []);
        setOneField('_managers', [
          {
            _id: '5e2727b8abaa1e004b769dc8',
            firstName: 'GESTION',
            lastName: 'Nettoyage'
          }
        ]);
        setDomaine(null);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'froid': {
        setOneField('_jobs', []);
        setOneField('_equipments', []);
        setEquipmentCategory([{ _id: '5e29edb69cbeb4004a7014b4' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'materiel': {
        setOneField('_jobs', []);
        setOneField('_equipments', []);
        setEquipmentCategory([{ _id: '5e29edc19cbeb4004a7014b6' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'four': {
        setOneField('_jobs', []);
        setOneField('_equipments', []);
        setEquipmentCategory([{ _id: '5e2c688659edf5004af58e23' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'autres': {
        setOneField('_jobs', [
          {
            _id: '5e39c2d071cfac004cba0ff1',
            name: 'Dépannage production non froid'
          }
        ]);
        setEquipmentCategory([{ _id: '613086d2b856200096edd351' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      case 'production_preventif': {
        setOneField('_jobs', []);
        setOneField('_equipments', []);
        setEquipmentCategory([{ _id: '61c46dd99c77ca5162452c56' }]);
        setDomaine(cat);
        setOneError('domaine', false);
        setLocalError(false);
        break;
      }
      default:
        break;
    }
  });

  return (
    <>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        exclusive
        value={category}
        onChange={handler.current}
      >
        {blachDomains.map((cat, i) => (
          <ToggleButton
            key={k1(i)}
            size="small"
            style={{
              width: '100%',
              fontSize: 11,
              color: category === cat.key ? 'white' : theme.palette.secondary.main,
              backgroundColor: category === cat.key ? theme.palette.secondary.main : ''
            }}
            value={cat.key}
          >
            {cat.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {category === 'production' && (
        <ToggleButtonGroup
          style={{ width: '100%', marginTop: '1em' }}
          value={domaine}
          onChange={handler.current}
          exclusive
        >
          {blachSubDomains
            .filter((f) => {
              return (
                f.key !== 'production_preventif' ||
                auth.interface.isManager ||
                ['5e2c59a359edf5004af58e20', '5e2c599859edf5004af58e1f', '5e2c597e59edf5004af58e1e'].includes(
                  auth.interface._role._id
                )
              );
            })
            .map((dom, i) => (
              <ToggleButton
                key={k2(i)}
                size="small"
                style={{
                  width: '100%',
                  fontSize: 11,
                  color: domaine === dom.key ? 'white' : theme.palette.secondary.main,
                  backgroundColor: domaine === dom.key ? theme.palette.secondary.main : ''
                }}
                value={dom.key}
              >
                {dom.label}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      )}

      {(categoryError || domaineError || localError) && (
        <FormHelperText
          style={{
            color: 'red',
            margin: '1em 0',
            marginLeft: '.5em'
          }}
        >
          {t('chooseCatAndDomain')}
        </FormHelperText>
      )}
    </>
  );
}
