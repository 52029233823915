const TicketTranslations = (config, { scheduler }) => ({
  pick: 'entityTicketPick',
  loading: 'entityTicketLoading',
  createDialog: 'entityTicketCreateDialog',
  updateDialog: 'entityTicketUpdateDialog',
  create: 'entityTicketCreate',
  update: 'entityTicketUpdate',
  entityName: 'interventions',
  noResultLabel: scheduler ? 'entityTicketnoResultLabel1' : 'entityTicketnoResultLabel2',
  searchText: 'entityTicketSearchText'
});

export default TicketTranslations;
