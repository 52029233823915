import { INTERFACE_CLIENT, INTERFACE_CONTRACTOR, INTERFACE_MASTER } from 'constants/roles';

export const roleTypes = {
  [INTERFACE_CLIENT]: [
    { label: 'managersTitle', value: 'client' },
    { label: 'techniciansTitle', value: 'collaborator' },
    { label: 'usersTitle', value: 'user' }
  ],
  [INTERFACE_CONTRACTOR]: [
    { label: 'managersTitle', value: 'contractor' },
    { label: 'techniciansTitle', value: 'collaborator' },
    { label: 'usersTitle', value: 'user' }
  ],
  [INTERFACE_MASTER]: [
    { label: 'bobTitle', value: 'master' },
    { label: 'clientManagersTitle', value: 'client' },
    { label: 'contractorManagersTitle', value: 'contractor' },
    { label: 'techniciansTitle', value: 'collaborator' },
    { label: 'usersTitle', value: 'user' }
  ]
};

export const getPermissions = (config, { type }) => {
  const permissions = {
    tickets: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          more: [{ label: 'Seuil', value: 'seuil', type: 'checkbox' }],
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'addDeleteTicketManagerPermission',
          key: 'managers',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'editTicketUrgencyPermission',
          key: 'urgency',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'editTicketJobPermission',
          key: 'job',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'editTicketVisitDate',
          key: 'visit_date',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'editTicketInterventionDate',
          key: 'intervention_date',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'replanTicketPermission',
          key: 'replan',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'estimateVisibilityPermission',
          key: 'display_devis',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'billVisibilityPermission',
          key: 'display_facture',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'uploadEstimatePermission',
          key: 'send_devis',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'uploadBillPermission',
          key: 'send_facture',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'deleteBillPermission',
          key: 'remove_facture',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'deleteEstimatePermission',
          key: 'remove_devis',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'validateTicketPermission',
          key: 'validate_ticket',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type),
          more: [{ type: 'checkbox', value: 'reason_ticket_mandatory' }]
        },
        {
          label: 'assignTicketPermission',
          key: 'assign_ticket',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'proposeTicketPermission',
          key: 'propose_ticket',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'validateEstimatePermission',
          key: 'validate_devis',
          more: [
            { type: 'input', inputType: 'number', value: 'minimum_seuil' },
            { value: 'reason_devis_mandatory', type: 'checkbox' }
          ],
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'endTicketPermission',
          key: 'finish_ticket',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'cancelTicketPermission',
          key: 'cancel',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'closeTicketPermission',
          key: 'close',
          show: ['client', 'user', 'contractor', 'collaborator'].includes(type)
        },
        {
          label: 'relancePermission',
          key: 'relance',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'addAlertPermission',
          key: 'create_deadline',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'showOnlyUserTickets',
          key: 'show_only_user_tickets',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'defineInterventionOrResolutionDelay',
          key: 'define_intervention_or_resolution_delay',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        }
      ]
    },
    accounting: {
      show: config.feature.comptability && ['client', 'user'].includes(type),
      permissions: [
        {
          label: 'accessAccountingPermission',
          key: 'view',
          show: ['client', 'user'].includes(type)
        },
        {
          label: 'editAmountsPermissions',
          key: 'update_price',
          show: ['client', 'user'].includes(type)
        },
        {
          label: 'linkBillToOtherInterventionsPermission',
          key: 'link',
          show: ['client', 'user'].includes(type)
        },
        {
          label: 'checkPermission',
          key: 'check_1',
          show: ['client', 'user'].includes(type) && config.comptability?.check_1 && config.comptability?.check_2
        },
        {
          label: 'validatePermission',
          key: 'check_1',
          show: ['client', 'user'].includes(type) && config.comptability?.check_1 && !config.comptability?.check_2
        },
        {
          label: 'validatePermission',
          key: 'check_2',
          show: ['client', 'user'].includes(type) && config.comptability?.check_1 && config.comptability?.check_2
        },
        { label: 'passOnBillPermission', key: 'send', show: ['client', 'user'].includes(type) },
        {
          label: 'markAsTransmittedPermission',
          key: 'mark_as_sent',
          show: ['client', 'user'].includes(type)
        },
        { label: 'openCloseEditsPermission', key: 'lock', show: ['client', 'user'].includes(type) }
      ]
    },
    locations: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'user', 'contractor'].includes(type)
        }
      ]
    },
    automatisations: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'readPermission',
          key: 'read',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['client', 'user', 'contractor'].includes(type)
        }
      ]
    },
    equipments: {
      show: true,
      permissions: [
        {
          label: 'readPermission',
          key: 'read',
          show: ['user', 'client', 'contractor'].includes(type)
        },
        {
          label: 'creationPermission',
          key: 'create',
          show: ['user', 'client', 'contractor'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['user', 'client', 'contractor'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['user', 'client', 'contractor'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['user', 'client', 'contractor'].includes(type)
        }
      ]
    },
    maintenances: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'contractor', 'user'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['client', 'contractor', 'user'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['client', 'contractor', 'user'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'contractor', 'user'].includes(type)
        },
        {
          label: 'changeMaintenanceDatePermission',
          key: 'dates',
          show: ['client', 'user', 'collaborator', 'contractor'].includes(type)
        },
        {
          label: 'liftReservePermission',
          key: 'lift_reserve',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'validateMaintenances',
          key: 'validation',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'maintenance-planning-view',
          key: 'planning_view',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        }
        // { label: "showSafetyCommittees", key: 'showSafety', show: ['client'].includes(type) },
      ]
    },
    users: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'contractor'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['client', 'contractor'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['client', 'contractor'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'contractor'].includes(type)
        }
      ]
    },
    reports: {
      show: true,
      permissions: [
        {
          label: 'createReportPermission',
          key: 'create_report',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'seeReportsPermission',
          key: 'read_report',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editReportsPermission',
          key: 'update_report',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'deleteReportPermission',
          key: 'delete_report',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'createReportTemplatePermission',
          key: 'create',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'editReportTemplatePermission',
          key: 'update',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'deleteReportTemplatePermission',
          key: 'delete',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'restoreReportTemplatePermission',
          key: 'restore',
          show: ['client', 'contractor'].includes(type)
        }
      ]
    },
    team: {
      show: true,
      permissions: [
        {
          label: 'createChannelsPermission',
          key: 'cannal',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        }
      ]
    },
    roles: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'contractor'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['client', 'contractor'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['client', 'contractor'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'contractor'].includes(type)
        }
      ]
    },
    technicians: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'contractor'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['client', 'contractor'].includes(type) },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['client', 'user', 'contractor'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'user', 'contractor'].includes(type)
        }
      ]
    },
    contractors: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['client', 'contractor'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['client', 'contractor'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['client', 'contractor'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'contractor'].includes(type)
        }
      ]
    },
    clients: {
      show: config.isContractor,
      permissions: [
        { label: 'creationPermission', key: 'create', show: ['contractor'].includes(type) },
        { label: 'editPermission', key: 'update', show: ['contractor'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['contractor'].includes(type) },
        { label: 'restorePermission', key: 'restore', show: ['contractor'].includes(type) }
      ]
    },
    managers: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['contractor', 'client'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['client', 'contractor'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['client', 'contractor'].includes(type)
        }
      ]
    },
    stocks: {
      show: config.feature.inventory,
      permissions: [
        {
          label: 'createStockLocationsPermission',
          key: 'create_warehouse',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editStockLocationsPermission',
          key: 'update_warehouse',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'deleteStockLocationsPermission',
          key: 'delete_warehouse',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'restoreStockLocationsPermission',
          key: 'restore_warehouse',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'addInventoryProductsPermission',
          key: 'create',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editInventoryProductsPermission',
          key: 'update',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'deleteInventoryProductsPermission',
          key: 'delete',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'restoreInventoryProductsPermission',
          key: 'restore',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editProductCountPermission',
          key: 'order_product',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'createSupplierPermission',
          key: 'create_supplier',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editSupplierPermission',
          key: 'update_supplier',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'deleteSupplierPermission',
          key: 'delete_supplier',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'restoreSupplierPermission',
          key: 'restore_supplier',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'defineInterventionCostsPermission',
          key: 'consommation_ticket',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        }
      ]
    },
    jobs: {
      show: true,
      permissions: [
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'editPermission',
          key: 'update',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'deletePermission',
          key: 'delete',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        }
      ]
    },
    guarantees: {
      show: config.feature.guarantee,
      permissions: [
        {
          label: 'seeListPermission',
          key: 'read',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['contractor', 'client'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['contractor', 'client'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['contractor', 'client'].includes(type)
        }
      ]
    },
    contracts: {
      show: config.feature.contract,
      permissions: [
        {
          label: 'seeListPermission',
          key: 'read',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['contractor', 'client'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['contractor', 'client'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['contractor', 'client'].includes(type)
        }
      ]
    },
    leases: {
      show: config.feature.lease,
      permissions: [
        {
          label: 'seeListPermission',
          key: 'read',
          show: ['client', 'contractor', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['contractor', 'client'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['contractor', 'client'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['contractor', 'client'].includes(type)
        }
      ]
    },
    apis: {
      show: config.isMaster,
      permissions: [
        { label: 'creationPermission', key: 'create', show: true },
        { label: 'editPermission', key: 'update', show: true },
        { label: 'deletePermission', key: 'delete', show: true },
        { label: 'restorePermission', key: 'restore', show: true }
      ]
    },
    bobdesk: {
      show: true,
      permissions: [
        { label: 'enterprise', key: 'company', show: ['client', 'contractor'].includes(type) },
        { label: 'settings', key: 'configurations', show: ['client', 'contractor'].includes(type) },
        { label: 'fields', key: 'fields', show: ['client', 'contractor'].includes(type) },
        { label: 'roles', key: 'roles', show: ['contractor', 'client'].includes(type) },
        { label: 'export', key: 'export', show: ['contractor', 'client'].includes(type) },
        { label: 'jobs', key: 'jobs', show: ['contractor', 'client'].includes(type) },
        { label: 'reports', key: 'reports', show: ['contractor', 'client'].includes(type) },
        { label: 'dashboard', key: 'dashboard', show: ['contractor', 'client'].includes(type) },
        { label: 'security', key: 'security', show: ['contractor', 'client'].includes(type) },
        {
          label: 'updateCompanyInfo',
          key: 'update_company_info',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        },
        {
          label: 'updateAccountInfo',
          key: 'update_account_info',
          show: ['contractor', 'client', 'user', 'collaborator'].includes(type)
        }
      ]
    },
    documents: {
      show: true,
      permissions: [
        { label: 'editDeleteDocument', key: 'document-owner', show: true },
        { label: 'editDeleteTag', key: 'tag-owner', show: true }
      ]
    },
    meters: {
      show: config.feature.meters,
      permissions: [
        { label: 'seeListPermission', key: 'read', show: ['client', 'contractor'].includes(type) },
        {
          label: 'creationPermission',
          key: 'create',
          show: ['contractor', 'client'].includes(type)
        },
        { label: 'editPermission', key: 'update', show: ['contractor', 'client'].includes(type) },
        { label: 'deletePermission', key: 'delete', show: ['contractor', 'client'].includes(type) },
        {
          label: 'restorePermission',
          key: 'restore',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'seeListReadingPermission',
          key: 'read-reading',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'creationReadingPermission',
          key: 'create-reading',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'editReadingPermission',
          key: 'update-reading',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'deleteReadingPermission',
          key: 'delete-reading',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'seeListPricingPermission',
          key: 'read-pricing',
          show: ['client', 'contractor'].includes(type)
        },
        {
          label: 'creationPricingPermission',
          key: 'create-pricing',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'editPricingPermission',
          key: 'update-pricing',
          show: ['contractor', 'client'].includes(type)
        },
        {
          label: 'deletePricingPermission',
          key: 'delete-pricing',
          show: ['contractor', 'client'].includes(type)
        }
      ]
    }
  };

  return Object.keys(permissions).reduce((a, b) => {
    const pp = permissions[b].permissions.filter((c) => config.isMaster || c.show);
    if (config.isMaster || permissions[b].show) {
      return { ...a, [b]: pp };
    }
    return a;
  }, {});
};
