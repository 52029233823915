import { useField } from 'frmx';
// import Popover from "components/ui/Popover";
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import useKeys from '@flowsn4ke/usekeys';
import { pick } from 'lodash-es';
import NotEmptyIndicator from './NotEmptyIndicator';
import { useState } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

//* The options should have the following structure: { _id, label, color }
export default function ListPopoverFilter({ label, options, path, className }) {
  const { t } = useTranslation();
  const k = useKeys();
  const { value: elements, setValue: setElements } = useField(path);
  const isSelected = (element) => elements.find((el) => el._id === element._id);

  const [is_list_open, set_is_list_open] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handle_click = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    set_is_list_open(!is_list_open);
  };

  return (
    <>
      <div
        onClick={(event) => handle_click(event)}
        className="relative cursor-pointer border border-gray-300 inline-flex items-center px-3 h-8 rounded-full text-xs font-medium text-gray-500 whitespace-nowrap"
      >
        <NotEmptyIndicator path={path} />
        {t(label)}
        <FAIcon
          icon="caret-down"
          collection="fas"
          size="xs"
          className="text-gray-600 ml-1"
        />
      </div>
      <MuiPopover
        open={is_list_open}
        transitionDuration={0}
        anchorEl={anchorEl}
        onClose={() => set_is_list_open(!is_list_open)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <>
          <div
            className={classNames(
              'flex flex-col overflow-auto z-40 py-[5px] max-w-screen xs:max-h-screen w-[calc(100vw-.5rem)] md:w-96 max-h-[calc(100vh*.75)] md:max-h-[500px]',
              className
            )}
          >
            {options?.map((option, i) => (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  if (isSelected(option)) {
                    setElements((elements) => elements.filter((el) => el._id !== option._id));
                  } else {
                    setElements((elements) => [...elements, pick(option, ['_id', 'label', 'color'])]);
                  }
                }}
                key={k(i)}
                className="cursor-pointer flex w-full h-8 items-center text-base font-normal hover:font-medium text-gray-900 border-gray-300 hover:bg-slate-50"
              >
                <div className="w-12 flex items-center justify-center text-center">
                  <FAIcon
                    collection="fal"
                    // icon="check"
                    icon={isSelected(option) ? 'square-check' : 'square'}
                    className={classNames('mx-0.5', isSelected(option) ? ' text-slate-400' : 'text-slate-200')}
                  />
                </div>
                {option.color && (
                  <span
                    className="w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: option.color }}
                  />
                )}
                {t(option.label)}
              </div>
            ))}
          </div>
        </>
      </MuiPopover>
    </>
  );
}
