import Scope from 'entities/Scope/Scope';

const ClientsFilters = (config, { customFields }) =>
  [
    {
      label: 'domains',
      key: 'categories',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: { type: 'clientCategory' }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'type',
      key: 'regime',
      type: 'List',
      params: {
        datas: [
          {
            label: 'professional',
            value: 'pro'
          },
          {
            label: 'individual',
            value: 'part'
          }
        ]
      },
      disabled: false,
      show: true,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters, auth) => {
  if (!filters.clients?.length && auth.interface._scope_clients) {
    filters['only'] = auth.interface._scope_clients;
  }

  return filters;
};

export default ClientsFilters;
