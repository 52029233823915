import { Grid, Box, CardHeader, CardContent, Checkbox, Typography, ButtonBase } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { getFeatures } from 'config/settingsMenu';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useAuth } from 'hooks/useAuth';
import { setConfiguration } from 'store/authSlice';

import useStyles from './Features.styles';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export default function Features({ section }) {
  const auth = useAuth();
  const configuration = auth.interface._company._configuration;
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const classes = useStyles();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };
  const k = useKeys();

  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={2}
    >
      {getFeatures(auth.interface).map((feature, i) => (
        <Grid
          item
          sm={12}
          md={6}
          key={k(i)}
        >
          <ButtonBase
            style={{ width: '100%', height: '100%' }}
            component="div"
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              dispatch(
                setConfiguration,
                {
                  feature: {
                    ...configuration?.feature,
                    [feature?.key]: !configuration?.feature[feature?.key]
                  }
                },
                dispatchCallbacks
              );
            }}
          >
            <Box
              boxShadow={1}
              className={classes.card}
            >
              <CardHeader
                avatar={
                  feature.img ? (
                    feature.img
                  ) : (
                    <FAIcon
                      collection="fad"
                      size="large"
                      className={classes.icon}
                      icon={feature.icon}
                    />
                  )
                }
                title={
                  <Typography
                    component="span"
                    variant="subtitle1"
                  >
                    <strong className={classes.title}>{t(feature.title)}</strong>
                  </Typography>
                }
                action={
                  <Checkbox
                    onMouseDown={(e) => [e.preventDefault(), e.stopPropagation()]}
                    checked={configuration.feature[feature.key]}
                    color="primary"
                  />
                }
              />
              <CardContent>{t(feature.descriptionKey)}</CardContent>
            </Box>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  );
}
