import { useField } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getColor } from 'router/Corentin/utils';

export default function SectionPicker({ path, label, color = 'green' }) {
  const { t } = useTranslation();
  const { value: isChecked, setValue: setIsChecked } = useField(path);
  const {
    activeIconColor,
    inactiveIconColor,
    inactiveTextColor,
    activeTextColor,
    inactiveBorderColor,
    activeBorderColor
  } = getColor(color);

  return (
    <button
      onClick={() => setIsChecked((prev) => !prev)}
      className={`cursor-pointer border ${isChecked ? activeBorderColor : inactiveBorderColor}  ${
        isChecked ? activeTextColor : inactiveTextColor
      } inline-flex text-gray-500 items-center pl-2 pr-3 h-8 rounded-full text-xs font-medium hover:bg-gray-50 transition`}
    >
      <FAIcon
        collection={isChecked ? 'fas' : 'fal'}
        icon={isChecked ? 'circle-check' : 'circle'}
        size="small"
        className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
      />
      {/* <CheckCircle style={{ color: isChecked ? activeIconColor : inactiveIconColor, position: 'relative', right: 8 }} /> */}
      <span className="whitespace-nowrap">{t(label)}</span>
    </button>
  );
}
