import Popover from 'components/ui/Popover';
import { cloneElement } from 'react';

export default function Menu({ button, items, className, placement = 'bottom-start' }) {
  return (
    <Popover
      offset={[10, 1]}
      placement={placement}
      className="z-20"
      button={button}
      contents={({ close }) => (
        <div className={`flex flex-col z-20 rounded w-auto p-0.5 ${className}`}>
          {items.map((menuItem, menuIdx) => cloneElement(menuItem, { key: menuIdx, close }))}
        </div>
      )}
    />
  );
}
